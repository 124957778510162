import { KeycloakEventType, KeycloakOptions, KeycloakService } from 'keycloak-angular';

const keycloakOptions: KeycloakOptions = {
    config: (window as any).keycloakConfig,
    bearerExcludedUrls: ['/assets',
        '/clients/public',
        'https://pgw.faso-arzeka.com/*',
        '/teleprocedure/frequently/*',
        '/referentiel/procedures',
        '/publications/by-all-visible/*',
        '/validees/not-connect'
    ],
    initOptions: {
        useNonce: true,
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
        enableLogging: (window as any).globalConfig.logger,
        checkLoginIframe: true
    },
    shouldAddToken: (request) => {
        const {method, url} = request;

        const isGetRequest = 'GET' === method.toUpperCase();
        const acceptablePaths = ['/assets', '/clients/public'];
        const isAcceptablePathMatch = acceptablePaths.some((path) => url.includes(path));

        return !(isGetRequest && isAcceptablePathMatch);
    }
};

/**
 * Fonction de configuration de Keycloak.
 * @param keycloak
 */
export function initializeKeycloak(keycloak: KeycloakService) {
    // Auto refresh token, when token expired.
    keycloak.keycloakEvents$.subscribe({
        next: async (e) => {
            if (e.type === KeycloakEventType.OnTokenExpired) {
                return keycloak.updateToken(20);
            } else if (e.type === KeycloakEventType.OnAuthSuccess) {
                return new Promise((resolve, reject) => resolve());
            } else if (e.type === KeycloakEventType.OnAuthLogout) {
                return new Promise((resolve, reject) => resolve());
            }
        }
    });
    return () => {
        return keycloak.init(keycloakOptions);
    };
}

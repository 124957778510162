<!-- Inner Banner -->
<p-blockUI  [blocked]="isRecupFaqs">


    <div class="bloked align-items-center">
        <div class="spiner">
            <p-progressSpinner
                styleClass="w-4rem h-4rem"
                strokeWidth="8"

                animationDuration=".5s" />
        </div>

        <p>Veuillez patienter ...</p>

    </div>
</p-blockUI>







<div class="inner-banner inner-bg3">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Foire aux questions</h3>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>FAQ</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Faq Area -->
<div class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">

            <h2>Questions fréquemment posées</h2>
        </div>

        <div class="row align-items-center pt-45">
            <p [hidden]="faqs.length!=0&&!isRecupFaqs" class="noData">Aucune données trouvées!</p>
            <div class="col-lg-6 col-md-12" *ngFor="let faq of faqs; let i = index">
                <div class="faq-accordion">
                    <div class="card flex justify-content-center">
                        <p-panel header=" {{faq.question}} ?" [toggleable]="true" [collapsed]="true" >
                            <p>
                     {{faq.reponse}}
                            </p>
                        </p-panel>
                    </div>

                </div>
            </div>


        </div>
    </div>
</div>
<!-- Faq Area End -->

<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>Nous sommes à votre disposition pour répondre à toutes vos questions </h2>
                <p>Pour toute assistance concernant des problèmes non décrits ci-dessus, veuillez nous contacter .</p>
            </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->

<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Posez nous votre question</h2>
                            <p>Decrivez clairement votre préoccupation.</p>
                        </div>

                        <form class="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                        <input  type="text" name="nomComplet"  pInputText [(ngModel)]="asQuestion.nom" placeholder="Votre nom complet">
                                </div>
                                <div class="col-lg-6 col-sm-6">

                                        <input type="text" name="telephone"  pInputText [(ngModel)]="asQuestion.telephone" placeholder="Votre numéro de téléphone">

                                </div>


                                <div class="col-lg-12 col-sm-12">

                                        <input type="email"  name="email"  pInputText [(ngModel)]="asQuestion.email"  placeholder="Votre email">

                                </div>

                                <div class="col-lg-12 col-sm-12">

                                        <input type="text"   name="objet"  pInputText [(ngModel)]="asQuestion.objet"  placeholder="Quelle est votre préoccupation">

                                </div>

                                <div class="col-lg-12 col-md-12">

                                        <textarea    name="description"  pInputText [(ngModel)]="asQuestion.description"cols="30" rows="8" placeholder="Veuillez décrire votre préoccupation" ></textarea>

                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button  pButton pRipple label="Envoyer" icon="pi pi-send"  (click)="submit()"
                                            pTooltip="Envoyer votre requête" tooltipPosition="top"
                                            class="p-button-primary contact-btn m-1" >
                                    </button>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->

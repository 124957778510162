import {PROCEDURE_SERVICE_PREFIX, REFERENTIEL_SERVICE_PREFIX} from 'src/app/components/utils/app-utils';


export class DemandeAcctEndpoint {
    static readonly PROCEDURE_ROOT_URL = `${REFERENTIEL_SERVICE_PREFIX}/procedures`;
    static readonly DEMANDE_ROOT_URL = `${PROCEDURE_SERVICE_PREFIX}/acct-demandes`;
    static readonly DEMANDE_CREATE_URL = `${DemandeAcctEndpoint.DEMANDE_ROOT_URL}/save`;
    static readonly DEMANDE_UPDATE_URL = `${DemandeAcctEndpoint.DEMANDE_ROOT_URL}/update`;
    static readonly GET_EXTERNE_PROC = `${DemandeAcctEndpoint.PROCEDURE_ROOT_URL}/externe`;
    static readonly ALL_DEMANDE__URL = `${PROCEDURE_SERVICE_PREFIX}`;

}
export class DemandeDaEndpoint {
    static readonly ROOT = `${PROCEDURE_SERVICE_PREFIX}/da-demandes`;
    static readonly DEMANDE_CREATE_URL = `${DemandeDaEndpoint.ROOT}/save`;
    static readonly DEMANDE_ROOT_URL = `${PROCEDURE_SERVICE_PREFIX}/da-demandes`;
    static readonly DEMANDE_UPDATE_URL = `${DemandeDaEndpoint.DEMANDE_ROOT_URL}/update`;



}

export class DemandeDelfEndpoint {
    static readonly PROCEDURE_ROOT_URL = `${REFERENTIEL_SERVICE_PREFIX}/procedures`;
    static readonly DEMANDE_ROOT_URL = `${PROCEDURE_SERVICE_PREFIX}/delf-demandes`;
    static readonly DEMANDE_CREATE_URL = `${DemandeDelfEndpoint.DEMANDE_ROOT_URL}/save`;
    static readonly DEMANDE_UPDATE_URL = `${DemandeDelfEndpoint.DEMANDE_ROOT_URL}/update`;
}
export class DemandeDamofEndpoint {
    static readonly PROCEDURE_ROOT_URL = `${REFERENTIEL_SERVICE_PREFIX}/procedures`;
    static readonly DEMANDE_ROOT_URL = `${PROCEDURE_SERVICE_PREFIX}/damof`;
    static readonly DEMANDE_CREATE_URL = `${DemandeDamofEndpoint.DEMANDE_ROOT_URL}/save`;
    static readonly DEMANDE_UPDATE_URL = `${DemandeDamofEndpoint.DEMANDE_ROOT_URL}/update-demande-damof/`;
}

export class DemandeSrhEndpoint {
    static readonly PROCEDURE_ROOT_URL = `${REFERENTIEL_SERVICE_PREFIX}/procedures`;
    static readonly DEMANDE_ROOT_URL = `${PROCEDURE_SERVICE_PREFIX}/srh-demandes`;
    static readonly DEMANDE_CREATE_URL = `${DemandeSrhEndpoint.DEMANDE_ROOT_URL}/save`;
    static readonly DEMANDE_UPDATE_URL = `${DemandeSrhEndpoint.DEMANDE_ROOT_URL}/update`;
}

export class ConcoursEndpoint {
     static readonly CONCOURS_EN_COURS = `${PROCEDURE_SERVICE_PREFIX}/concours/encours`;
    static readonly LIST_CONCOURS = `${PROCEDURE_SERVICE_PREFIX}/concours`;
    static readonly LIST_INSCRIPTION_CONCOURS = `${PROCEDURE_SERVICE_PREFIX}/concours/inscription/user`;
    static readonly RESULTAT_CONCOURS = `${PROCEDURE_SERVICE_PREFIX}/concours/resultats`;
    static readonly MAKE_INSCRIPTION_CONCOURS = `${PROCEDURE_SERVICE_PREFIX}/concours/inscription/create`;
}


export class StructureEndpoint {
    static readonly STRUCTURE_ROOT_URL = `${REFERENTIEL_SERVICE_PREFIX}/structures`;
    static readonly STRUCTURE_CREATE_URL = `${StructureEndpoint.STRUCTURE_ROOT_URL}/create`;
    static readonly STRUCTURE_UPDATE_URL = `${StructureEndpoint.STRUCTURE_ROOT_URL}/update`;
    static readonly STRUCTURE_DELETE_URL = `${StructureEndpoint.STRUCTURE_ROOT_URL}/delete/$id$`;
    static readonly STRUCTURE_BY_ID_URL = `${StructureEndpoint.STRUCTURE_ROOT_URL}/$id$`;
}
export class ProcedureEndPoint{
    static readonly PROCEDURE_ROOT_URL = `${REFERENTIEL_SERVICE_PREFIX}/procedures/`;

}

export class DemandeDitEndpoint {
    static readonly DEMANDE_CODE_LOGICIEL_ROOT_URL = `${PROCEDURE_SERVICE_PREFIX}/demandes-code-logiciel`;
    static readonly DEMANDE_CODE_LOGICIEL_CREATE_URL = `${DemandeDitEndpoint.DEMANDE_CODE_LOGICIEL_ROOT_URL}/create`;
    static readonly DEMANDE_CODE_LOGICIEL_UPDATE_URL = `${DemandeDitEndpoint.DEMANDE_CODE_LOGICIEL_ROOT_URL}/update`;
    static readonly DEMANDE_CODE_LOGICIEL_DELETE_URL = `${DemandeDitEndpoint.DEMANDE_CODE_LOGICIEL_ROOT_URL}/delete/$id$`;

    static readonly LOGICIEL_ALL_URL = `${PROCEDURE_SERVICE_PREFIX}/logiciel/liste/all`;
    static readonly PROFIL_ALL_URL = `${PROCEDURE_SERVICE_PREFIX}/profil/liste/all`;
}

export class DemandeBcmsEndpoint {
    static readonly DEMANDE_BCMS_ROOT_URL = `${PROCEDURE_SERVICE_PREFIX}/bcms-demandes`;
    static readonly DEMANDE_BCMS_CREATE_URL = `${DemandeBcmsEndpoint.DEMANDE_BCMS_ROOT_URL}/save`;
    static readonly DEMANDE_BCMS_UPDATE_URL = `${DemandeBcmsEndpoint.DEMANDE_BCMS_ROOT_URL}/update`;
    static readonly DEMANDE_BCMS_DELETE_URL = `${DemandeBcmsEndpoint.DEMANDE_BCMS_ROOT_URL}/delete/$id$`;
}

export class DemandeTmdcEndpoint {
    static readonly DEMANDE_TMDC_ROOT_URL = `${PROCEDURE_SERVICE_PREFIX}/tmdc-demandes`;
    static readonly DEMANDE_TMDC_CREATE_URL = `${DemandeTmdcEndpoint.DEMANDE_TMDC_ROOT_URL}/save`;
    static readonly DEMANDE_TMDC_UPDATE_URL = `${DemandeTmdcEndpoint.DEMANDE_TMDC_ROOT_URL}/update`;
    static readonly DEMANDE_TMDC_DELETE_URL = `${DemandeTmdcEndpoint.DEMANDE_TMDC_ROOT_URL}/delete/$id$`;
}


export class DemandeRgEndpoint {
    static readonly DEMANDE_RG_ROOT_URL = `${PROCEDURE_SERVICE_PREFIX}/rg-demandes`;
    static readonly DEMANDE_RG_CREATE_URL = `${DemandeRgEndpoint.DEMANDE_RG_ROOT_URL}/save`;
    static readonly DEMANDE_RG_UPDATE_URL = `${DemandeRgEndpoint.DEMANDE_RG_ROOT_URL}/update`;
    static readonly DEMANDE_RG_DELETE_URL = `${DemandeRgEndpoint.DEMANDE_RG_ROOT_URL}/delete/$id$`;
}


export class DemandeIttEndpoint {
    static readonly DEMANDE_ITT_ROOT_URL = `${PROCEDURE_SERVICE_PREFIX}/itt-demandes`;
    static readonly DEMANDE_ITT_CREATE_URL = `${DemandeIttEndpoint.DEMANDE_ITT_ROOT_URL}/save`;
    static readonly DEMANDE_ITT_UPDATE_URL = `${DemandeIttEndpoint.DEMANDE_ITT_ROOT_URL}/update`;
    static readonly DEMANDE_ITT_DELETE_URL = `${DemandeIttEndpoint.DEMANDE_ITT_ROOT_URL}/delete/$id$`;
}



export class DeamandeUrl{
    static readonly MOST_FREQUENTLY = `${PROCEDURE_SERVICE_PREFIX}/teleprocedure`;
    static readonly DEMANDE_PJ_URL = `/type-piece-jointes/teleprocedure`;
}

import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AskQuestion} from "../faq/faq.model";
import {Observable} from "rxjs";
import {ContactUrls} from "./Contact.urls";

@Injectable({
  providedIn: 'root'
})
export class ContactService {

    constructor(private http: HttpClient) { }

    sendContactResponseToAdmin(askQuestion: AskQuestion): Observable<void> {
        return this.http.post<void>(ContactUrls.SEND_CONTACT_URL, askQuestion);
    }
}

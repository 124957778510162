import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpRequest } from '@angular/common/http';
import { SearchInfos } from './components/pages/espace-utilisateur/lanaya/lanaya.model';
import { Message, MessageService } from 'primeng/api';

@Injectable({providedIn: 'root'})
export class FeaturesService {
    private searchInfos = new Subject<SearchInfos>();
    searchInfos$ = this.searchInfos.asObservable();

    loader = new BehaviorSubject<boolean>(false);
    requests: Array<HttpRequest<any>> = [];

    public searchData: SearchInfos;
    private reaload = new Subject<boolean>();
    reaload$ = this.reaload.asObservable();

    constructor(private messageService: MessageService) {
    }


    removeRequest(req: HttpRequest<any>) {
        if (this.requests.length > 0) {
            this.requests.splice(this.requests.indexOf(req), 1);
        }

        this.loader.next(this.requests.length > 0);
    }

    addRequest(req: HttpRequest<any>) {
        this.requests.push(req);

        this.loader.next(true);
    }

    onSearchInfosChange(event: SearchInfos) {
        this.searchData = event;
        this.searchInfos.next(event);
    }

    onReloadData(event: boolean) {
        this.reaload.next(event);
    }

    showToast(message: Message) {
        this.messageService.add(message);
    }
}

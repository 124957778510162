<div class="inner-banner inner-bg2">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Espace de vérification des documents produits par Lanaya</h3>
        </div>
    </div>
</div>
<section class="demande p-4">
    <div id="msform" class="card-merci">
        <div class="col-12">
            <h2 *ngIf="isVerified">Document reconnu par Lanaya.</h2>
        </div>
        <div style="text-align: center">
            @if (isVerified) {
                <img src="../../../../../assets/img/succes.jpeg" width="200" alt="">
            } @else {
                <img src="../../../../../assets/img/error.avif" width="200" alt="">
            }
        </div>
        <div class="text-center">
            <h3 *ngIf="isVerified">N° demande : <label class="ml-5">{{ checkValidityData.numeroDemande }}</label></h3>
            <h2 *ngIf="!isVerified">Document non reconnu par Lanaya.</h2>
        </div>
    </div>
</section>


import { Component, OnInit } from '@angular/core';
import { Procedure } from '../../pages/document-administratif/demande-administratif.models';
import { PROCEDURE_ACCT } from '../../pages/document-administratif/demande-administratif.procedure';
import { DemandeAdministratifService } from '../../pages/document-administratif/demande-administratif.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    procedures: Procedure[] = PROCEDURE_ACCT;
    demandes: any[] = [];
    limite = 3;
    proceduresFrequentlyFooter: Procedure[] = PROCEDURE_ACCT;
    appVersion = environment.VERSION;

    constructor(private demandeService: DemandeAdministratifService) {
    }
    toutesLesProcedures=[]
    ngOnInit(): void {
        this.demandeService.getProcedures().subscribe({
            next:(response)=>{
                this.toutesLesProcedures=response.body


            },
            error:(errors)=>{
                console.log("une erreur est survenue lors de la recuperation des procedures les plus utilisées")
            },
            complete:console.log
        })
        this.demandeService.getMostFrequentlyUsed(3).subscribe(data => {
            this.demandes = data.body;
            this.proceduresFrequentlyFooter =data.body;

            if (this.proceduresFrequentlyFooter.length == 0) {
                this.proceduresFrequentlyFooter =  this.toutesLesProcedures;
            }
        });

        if (this.proceduresFrequentlyFooter.length < this.limite) {
            let i = 0;
            while (this.proceduresFrequentlyFooter.length < this.limite && i <  this.toutesLesProcedures.length) {
                if ( this.toutesLesProcedures[i].loadAsFrequently) {
                    this.proceduresFrequentlyFooter.push( this.toutesLesProcedures[i]);
                }
                i++;
            }
        }

    }

}

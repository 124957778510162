import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { UserProfile } from '../../models/user';
import {DemandeAdministratifService} from "../../pages/document-administratif/demande-administratif.service";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    islogging: Boolean = false;
    user?: UserProfile;
    display: Boolean = false;
    structureList: any[];
    constructor(private ks: KeycloakService,private demandeAdministratifService: DemandeAdministratifService,) {
    }

    ngOnInit(): void {

        if (this.ks.isLoggedIn()) {
            this.islogging = true;
            this.ks.loadUserProfile().then(userProfile => {
                this.user = new UserProfile(userProfile);

            });

        }
        this.demandeAdministratifService.getAllStructure().subscribe({
            next: resp => {
                if (resp.body && resp.body.length > 0) {
                    this.structureList = resp.body
                        .map(item => ({
                            value: item.id,
                            label: item.libelleLong
                        }));
                }
            }
        });
    }
    login() {
        this.ks.login({
            redirectUri: window.location.origin // Spécifiez l'URL de redirection ici
        }).then(r => console.log('une erreur est survenue==========>'));
    }

    register() {
        this.ks.register();

    }
    logout() {
        localStorage.removeItem("user");
        this.ks.logout(
            window.location.origin
        );
    }

    classApplied = false;

    toggleClass() {
        this.classApplied = !this.classApplied;
    }

}

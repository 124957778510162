import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ReponseEnqueteDto } from '../../../models/ReponseEnquete';
import { EMPTY, mergeMap, Observable, of, take } from 'rxjs';
import { PROCEDURE_SERVICE_PREFIX } from '../../../utils/app-utils';
import {Enquete} from '../../../models/enquete';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class EnqueteService {

    constructor(private http: HttpClient) {
    }

    soummetreReponse(reponseEnqueteDto: ReponseEnqueteDto): Observable<HttpResponse<ReponseEnqueteDto>> {
        return this.http.post<ReponseEnqueteDto>(`${PROCEDURE_SERVICE_PREFIX}/reponse`, reponseEnqueteDto, {observe: 'response'});
    }

    getEnquetes(): Observable<HttpResponse<Array<Enquete>>> {
        return this.http.get<Array<Enquete>>(`${PROCEDURE_SERVICE_PREFIX}/enquetes/validees/connect`, {observe: 'response'});
    }
    getEnquetesNotConnect(): Observable<HttpResponse<Array<Enquete>>> {
        return this.http.get<Array<Enquete>>(`${PROCEDURE_SERVICE_PREFIX}/enquetes/validees/not-connect`, {observe: 'response'});
    }

    getById(id: string): Observable<HttpResponse<Enquete>> {
        return this.http.get<Enquete>(`${PROCEDURE_SERVICE_PREFIX}/enquetes/consulter?enqueteId=${id}`, {observe: 'response'});
    }
    getReponseById(id: string): Observable<HttpResponse<ReponseEnqueteDto>> {
        return this.http.get<ReponseEnqueteDto>(`${PROCEDURE_SERVICE_PREFIX}/reponse/consulter?id=${id}`, {observe: 'response'});
    }

}

export const enqueteResolver: ResolveFn<Enquete> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    return inject(EnqueteService).getById(route.paramMap.get('id'))
        .pipe(
            take(1),
            mergeMap((resp) => {
                if (resp.body) {
                    return of(resp.body);
                } else {
                    return EMPTY;
                }
            })
        );
};
export const ReponseResolver: ResolveFn<Enquete> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    return inject(EnqueteService).getReponseById(route.paramMap.get('id'))
        .pipe(
            take(1),
            mergeMap((resp) => {
                if (resp.body) {
                    return of(resp.body);
                } else {
                    return EMPTY;
                }
            })
        );
};
export const procedureResolver: ResolveFn<String> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    return route.paramMap.get('type');
};

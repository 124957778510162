import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './components/pages/home-page/home-page.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { LocationStrategy, NgOptimizedImage, PathLocationStrategy } from '@angular/common';
import { initializeKeycloak } from './security/keycloak.init';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgPrimeModule } from './ng-prime.module';
import { NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';
import { FaqComponent } from './components/pages/faq/faq.component';
import { BlockUIModule } from 'primeng/blockui';
import { LoaderComponent } from './components/common/loader/loader.component';
import { LoaderInterceptor } from './components/interceptors/loader.interceptor';
import {
    CheckDocValidatyComponent
} from './components/pages/check-doc-validity/check-doc-validity/check-doc-validaty.component';
import { PaymentComponent } from './components/pages/payment/payment.component';

@NgModule({
    declarations: [
        AppComponent,
        HomePageComponent,
        PreloaderComponent,
        FooterComponent,
        NavbarComponent,
        ErrorComponent,
        AboutComponent,
        ContactComponent,
        FaqComponent,
        LoaderComponent,
        CheckDocValidatyComponent,
        PaymentComponent

    ],
    exports: [
        LoaderComponent
    ],
    imports: [
        BrowserModule,
        DropdownModule,
        FormsModule,
        ProgressSpinnerModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        NgOptimizedImage,
        KeycloakAngularModule,
        BrowserAnimationsModule,
        BlockUIModule,
        NgPrimeModule,
        NgxMaskDirective
    ],
    providers: [
        {provide: LocationStrategy, useClass: PathLocationStrategy},
        MessageService,
        ConfirmationService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true

        },
        provideEnvironmentNgxMask({validation: false})
    ],
    bootstrap: [AppComponent]

})
export class AppModule {
}

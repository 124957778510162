import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { EMPTY, mergeMap, Observable, of, take } from 'rxjs';
import { createRequestOption, formatUrl, PROCEDURE_SERVICE_PREFIX } from 'src/app/components/utils/app-utils';
import {
    CheckValidityData,
    Concours,
    DemandeCodeLogiciel,
    DemandeDocAdminACCTDto,
    DemandeDocAdminBcmsDto,
    DemandeDocAdminDamofDto,
    DemandeDocAdminDELFDto,
    DemandeDocAdminITTDto,
    DemandeDocAdminRGDto,
    DemandeDocAdminSRHDto,
    DemandeDocAdminTMDCDto,
    InscriptionConcours,
    Logiciel,
    Procedure,
    Profil,
    Structure,
    TypeDemande,
    TypePieceJointe,
    TypeStructure
} from './demande-administratif.models';
import {
    ConcoursEndpoint,
    DeamandeUrl,
    DemandeAcctEndpoint,
    DemandeBcmsEndpoint,
    DemandeDaEndpoint,
    DemandeDamofEndpoint,
    DemandeDelfEndpoint,
    DemandeDitEndpoint,
    DemandeIttEndpoint,
    DemandeRgEndpoint,
    DemandeSrhEndpoint,
    DemandeTmdcEndpoint,
    ProcedureEndPoint,
    StructureEndpoint
} from './demande-administratif.urls';
import {
    PROCEDURE_ACCT,
    PROCEDURE_DA,
    PROCEDURE_DAMOF,
    PROCEDURE_DELF,
    PROCEDURE_DIT,
    PROCEDURE_SRH
} from './demande-administratif.procedure';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Enquete } from '../../models/enquete';
import { AgentPublicDto } from './demande-dit/demande-dit.data';

@Injectable({
    providedIn: 'root'
})
export class DemandeAdministratifService {
    constructor(private http: HttpClient) {
    }

    public createDemandeAcct(demande: DemandeDocAdminACCTDto): Observable<HttpResponse<DemandeDocAdminACCTDto>> {
        return this.http.post<DemandeDocAdminACCTDto>(formatUrl(DemandeAcctEndpoint.DEMANDE_CREATE_URL), demande, {observe: 'response'});
    }

    public createDemandeDa(demande: DemandeDocAdminACCTDto): Observable<HttpResponse<DemandeDocAdminACCTDto>> {
        return this.http.post<DemandeDocAdminACCTDto>(formatUrl(DemandeDaEndpoint.DEMANDE_CREATE_URL), demande, {observe: 'response'});
    }


    public getAllStructure(typeStructure?: TypeStructure, directionId?: string): Observable<HttpResponse<Array<Structure>>> {
        const params = createRequestOption({typeStructure, directionId});
        return this.http.get<Array<Structure>>(StructureEndpoint.STRUCTURE_ROOT_URL, {observe: 'response', params});
    }

    getServiceById(id: string): Observable<HttpResponse<Structure>> {
        return this.http.get<Structure>(formatUrl(StructureEndpoint.STRUCTURE_BY_ID_URL, id), {observe: 'response'});
    }

    public updtaeDemandeAcct(demandeId: string, demande: DemandeDocAdminACCTDto): Observable<HttpResponse<DemandeDocAdminACCTDto>> {
        return this.http.post<DemandeDocAdminACCTDto>(`${PROCEDURE_SERVICE_PREFIX}/acct-demandes/update/${demandeId}`, demande, {observe: 'response'});
    }


    public createDemandeCodeLogiciel(demande: DemandeCodeLogiciel): Observable<HttpResponse<DemandeCodeLogiciel>> {
        return this.http.post<DemandeCodeLogiciel>(formatUrl(DemandeDitEndpoint.DEMANDE_CODE_LOGICIEL_CREATE_URL), demande, {observe: 'response'});
    }

    // Les methode de SRH ***
    public createDemandeSrh(demande: DemandeDocAdminSRHDto): Observable<HttpResponse<DemandeDocAdminSRHDto>> {
        return this.http.post<DemandeDocAdminSRHDto>(formatUrl(DemandeSrhEndpoint.DEMANDE_CREATE_URL), demande, {observe: 'response'});
    }

    public updtaeDemandeSrh(demandeId: string, demande: DemandeDocAdminSRHDto): Observable<HttpResponse<DemandeDocAdminSRHDto>> {
        return this.http.post<DemandeDocAdminSRHDto>(`${PROCEDURE_SERVICE_PREFIX}/srh-demandes/update/${demandeId}`, demande, {observe: 'response'});
    }

    public createDemandeBcms(demande: DemandeDocAdminBcmsDto): Observable<HttpResponse<DemandeDocAdminBcmsDto>> {
        return this.http.post<DemandeDocAdminBcmsDto>(formatUrl(DemandeBcmsEndpoint.DEMANDE_BCMS_CREATE_URL), demande, {observe: 'response'});
    }

    public createDemandeTmdc(demande: DemandeDocAdminTMDCDto): Observable<HttpResponse<DemandeDocAdminTMDCDto>> {
        return this.http.post<DemandeDocAdminTMDCDto>(formatUrl(DemandeTmdcEndpoint.DEMANDE_TMDC_CREATE_URL), demande, {observe: 'response'});
    }


    public createDemandeRg(demande: DemandeDocAdminRGDto): Observable<HttpResponse<DemandeDocAdminRGDto>> {
        return this.http.post<DemandeDocAdminRGDto>(formatUrl(DemandeRgEndpoint.DEMANDE_RG_CREATE_URL), demande, {observe: 'response'});
    }

    public createDemandeItt(demande: DemandeDocAdminITTDto): Observable<HttpResponse<DemandeDocAdminITTDto>> {
        return this.http.post<DemandeDocAdminITTDto>(formatUrl(DemandeIttEndpoint.DEMANDE_ITT_CREATE_URL), demande, {observe: 'response'});
    }

    public updateDemandeBcms(demandes: DemandeDocAdminBcmsDto): Observable<HttpResponse<DemandeDocAdminBcmsDto>> {
        const url = `${DemandeBcmsEndpoint.DEMANDE_BCMS_UPDATE_URL}`;
        return this.http.put<DemandeDocAdminBcmsDto>(url, demandes, {observe: 'response'});
    }

    public updateDemandeTmdc(demandes: DemandeDocAdminTMDCDto): Observable<HttpResponse<DemandeDocAdminTMDCDto[]>> {
        const url = `${DemandeTmdcEndpoint.DEMANDE_TMDC_UPDATE_URL}`;
        return this.http.put<DemandeDocAdminTMDCDto[]>(url, demandes, {observe: 'response'});
    }

    public updateDemandeRg(demandes: DemandeDocAdminRGDto): Observable<HttpResponse<DemandeDocAdminRGDto[]>> {
        const url = `${DemandeRgEndpoint.DEMANDE_RG_UPDATE_URL}`;
        return this.http.put<DemandeDocAdminRGDto[]>(url, demandes, {observe: 'response'});
    }

    public updateDemandeItt(demandes: DemandeDocAdminITTDto): Observable<HttpResponse<DemandeDocAdminITTDto[]>> {
        const url = `${DemandeIttEndpoint.DEMANDE_ITT_UPDATE_URL}`;
        return this.http.put<DemandeDocAdminITTDto[]>(url, demandes, {observe: 'response'});
    }


    public updateDemandeDamof(demandeId: string, demande: DemandeDocAdminDamofDto): Observable<HttpResponse<DemandeDocAdminDamofDto>> {
        return this.http.post<DemandeDocAdminDamofDto>(`${DemandeDamofEndpoint.DEMANDE_UPDATE_URL}${demandeId}`, demande, {observe: 'response'});

    }

    // Service Method for BCMS
    public getAllDemandeDocAdminBCMSByUser(login: string): Observable<any> {
        return this.http.get<any[]>(DemandeBcmsEndpoint.DEMANDE_BCMS_ROOT_URL + '/username/' + login, {observe: 'response'});
    }


    public getProcedureByLibelle(structure: string): Observable<any> {
        return this.http.get<any[]>(DemandeAcctEndpoint.PROCEDURE_ROOT_URL + '/structure/' + structure, {observe: 'response'});
    }


    // Service Method for TMDC
    public getAllDemandeDocAdminTMDCByUser(login: string): Observable<any> {
        return this.http.get<any[]>(DemandeTmdcEndpoint.DEMANDE_TMDC_ROOT_URL + '/username/' + login, {observe: 'response'});
    }

    // Service Method for RG
    public getAllDemandeDocAdminRGByUser(login: string): Observable<any> {
        return this.http.get<any[]>(DemandeRgEndpoint.DEMANDE_RG_ROOT_URL + '/username/' + login, {observe: 'response'});
    }

    // Service Method for ITT
    public getAllDemandeDocAdminITTByUser(login: string): Observable<any> {
        return this.http.get<any[]>(DemandeIttEndpoint.DEMANDE_ITT_ROOT_URL + '/username/' + login, {observe: 'response'});
    }

    public getDemandeDocAdminBCMSById(id: number): Observable<any> {
        return this.http.get<any>(DemandeBcmsEndpoint.DEMANDE_BCMS_ROOT_URL + '/' + id, {observe: 'response'});
    }

    public getDemandeDocAdminTMDCById(id: number): Observable<any> {
        return this.http.get<any>(DemandeTmdcEndpoint.DEMANDE_TMDC_ROOT_URL + '/' + id, {observe: 'response'});
    }

    public getDemandeDocAdminRGById(id: number): Observable<any> {
        return this.http.get<any>(DemandeRgEndpoint.DEMANDE_RG_ROOT_URL + '/' + id, {observe: 'response'});
    }

    public getDemandeDocAdminITTById(id: number): Observable<any> {
        return this.http.get<any>(DemandeIttEndpoint.DEMANDE_ITT_ROOT_URL + '/' + id, {observe: 'response'});
    }

    public getDemandeDocAdminDAMOFById(id: number): Observable<any> {
        return this.http.get<any>(DemandeDamofEndpoint.DEMANDE_ROOT_URL + '/' + id, {observe: 'response'});
    }

    //DEFL

    public createDemandeDelf(demande: DemandeDocAdminDELFDto): Observable<HttpResponse<DemandeDocAdminDELFDto>> {
        return this.http.post<DemandeDocAdminDELFDto>(formatUrl(DemandeDelfEndpoint.DEMANDE_CREATE_URL), demande, {observe: 'response'});
    }

    public updateDemandeDelf(demandeId: string, demande: DemandeDocAdminDELFDto): Observable<HttpResponse<DemandeDocAdminDELFDto>> {
        return this.http.put<DemandeDocAdminDELFDto>(`${PROCEDURE_SERVICE_PREFIX}/delf-demandes/update/${demandeId}`, demande, {observe: 'response'});
    }


    public getAllDemandeDocAdminDELFByUserAndTypeDemandee(login: string, typeDemande: string): Observable<HttpResponse<Array<Procedure>>> {
        return this.http.get<Array<Procedure>>(`${PROCEDURE_SERVICE_PREFIX}/delf-demandes/demande-doc-admin/by-user/${login}/${typeDemande}`, {observe: 'response'});

    }


    public getAllDemandeDocAdminDELFByUser(login: string): Observable<any> {
        return this.http.get<any[]>(DemandeDelfEndpoint.DEMANDE_ROOT_URL + '/username/' + login, {observe: 'response'});

    }


    public getMostFrequentlyUsed(limite: number): Observable<any> {
        return this.http.get<any[]>(DeamandeUrl.MOST_FREQUENTLY + '/frequently/' + limite, {observe: 'response'});

    }


    getlogiciel(): Observable<HttpResponse<Array<Logiciel>>> {
        return this.http.get<Array<Logiciel>>(DemandeDitEndpoint.LOGICIEL_ALL_URL, {observe: 'response'});
    }

    getlProfil(): Observable<HttpResponse<Array<Profil>>> {
        return this.http.get<Array<Profil>>(DemandeDitEndpoint.PROFIL_ALL_URL, {observe: 'response'});
    }

    public getAllDemandeDocAdminByUser(login: string): Observable<any> {
        return this.http.get<any[]>(DemandeAcctEndpoint.DEMANDE_ROOT_URL + '/demande-doc-admin/by-user/' + login, {observe: 'response'});

    }

    public getAllProcedures(libelle: string): Observable<HttpResponse<Array<Procedure>>> {
        return this.http.get<Array<Procedure>>(ProcedureEndPoint.PROCEDURE_ROOT_URL + 'by-service?libelle=' + libelle, {observe: 'response'});

    }

    public getProcedures(): Observable<HttpResponse<Array<Procedure>>> {
        return this.http.get<Array<Procedure>>(DemandeAcctEndpoint.PROCEDURE_ROOT_URL, {observe: 'response'});

    }

    public getExterneProcedures(): Observable<HttpResponse<Array<Procedure>>> {
        return this.http.get<Array<Procedure>>(DemandeAcctEndpoint.GET_EXTERNE_PROC, {observe: 'response'});

    }

    public getAllDemandeDocAdminSRHByUser(login: string): Observable<any> {
        return this.http.get<any[]>(DemandeSrhEndpoint.DEMANDE_ROOT_URL + '/username/' + login, {observe: 'response'});

    }

    public getAllDemandeDocAdminByUserAndTypeDemandee(login: string, typeDemande: string): Observable<HttpResponse<Array<Procedure>>> {
        return this.http.get<Array<Procedure>>(`${PROCEDURE_SERVICE_PREFIX}/acct-demandes/demande-doc-admin/by-user/${login}/${typeDemande}`, {observe: 'response'});

    }

    public getAllDemandeDocAdminSRHByUserAndTypeDemandee(login: string, typeDemande: string): Observable<HttpResponse<Array<Procedure>>> {
        return this.http.get<Array<Procedure>>(`${PROCEDURE_SERVICE_PREFIX}/srh-demandes/demande-doc-admin/by-user/${login}/${typeDemande}`, {observe: 'response'});

    }


    public fetchAllByTypeDemande(typeDemande: TypeDemande): Observable<HttpResponse<Array<TypePieceJointe>>> {
        console.log(typeDemande);
        return this.http.get<Array<TypePieceJointe>>('api/v1/referentiel/type-piece-jointes/' + typeDemande, {observe: 'response'});
    }

    public updateDemandeDa(demande: DemandeDocAdminACCTDto[]): Observable<HttpResponse<DemandeDocAdminACCTDto>> {
        return this.http.put<DemandeDocAdminACCTDto>(`${PROCEDURE_SERVICE_PREFIX}/da-demandes/update`, demande, {
            observe: 'response'
        });
    }


    findProcedureByType(type: string) {
        const allProcedures = [
            ...PROCEDURE_ACCT,
            ...PROCEDURE_DIT,
            ...PROCEDURE_SRH,
            ...PROCEDURE_DAMOF,
            ...PROCEDURE_DELF,
            ...PROCEDURE_DA

        ];
        return allProcedures.find(procedure => procedure.type === type);
    }

    getDemandeById(id: string): Observable<HttpResponse<any>> {
        return this.http.get<Enquete>(`${PROCEDURE_SERVICE_PREFIX}/acct-demandes/demande-doc-admin/by-userId/${id}`, {observe: 'response'});
    }

    getDemandeDelfById(id: string): Observable<HttpResponse<any>> {
        return this.http.get<Enquete>(`${PROCEDURE_SERVICE_PREFIX}/delf-demandes/demande-doc-admin/by-userId/${id}`, {observe: 'response'});
    }

    getDemandeCodeLogicielById(id: string): Observable<HttpResponse<DemandeCodeLogiciel>> {
        return this.http.get<DemandeCodeLogiciel>(`${PROCEDURE_SERVICE_PREFIX}/demandes-code-logiciel/${id}`, {observe: 'response'});
    }

    public createDemandeDamof(demande: DemandeDocAdminDamofDto): Observable<HttpResponse<DemandeDocAdminDamofDto>> {
        return this.http.post<DemandeDocAdminDamofDto>(formatUrl(DemandeDamofEndpoint.DEMANDE_CREATE_URL), demande, {observe: 'response'});
    }

    getByIAgentByMatricule(matricule: string): Observable<HttpResponse<AgentPublicDto>> {
        return this.http.get<AgentPublicDto>(`${PROCEDURE_SERVICE_PREFIX}/agents/matricule/${matricule}`, {observe: 'response'});
    }

    getByDemandeDaById(id: string): Observable<HttpResponse<DemandeDocAdminACCTDto>> {
        return this.http.get<DemandeDocAdminACCTDto>(`${PROCEDURE_SERVICE_PREFIX}/da-demandes/${id}`, {observe: 'response'});
    }

    public updateDemandeCode(demande: DemandeCodeLogiciel[]): Observable<HttpResponse<DemandeCodeLogiciel>> {
        return this.http.put<DemandeCodeLogiciel>(`${PROCEDURE_SERVICE_PREFIX}/demandes-code-logiciel/update`, demande, {
            observe: 'response'
        });
    }

    public loadConcoursEnCours(): Observable<Array<Concours>> {
        return this.http.get<Array<Concours>>(ConcoursEndpoint.CONCOURS_EN_COURS);
    }


    public makeInscription(inscription: InscriptionConcours): Observable<HttpResponse<InscriptionConcours>> {
        return this.http.post<InscriptionConcours>(formatUrl(ConcoursEndpoint.MAKE_INSCRIPTION_CONCOURS), inscription, {observe: 'response'});
    }

    public allUserInscription(email: string): Observable<Array<InscriptionConcours>> {
        return this.http.get<Array<InscriptionConcours>>(ConcoursEndpoint.LIST_INSCRIPTION_CONCOURS + '/' + email);
    }


    public updateDemandeDELF(demande: DemandeDocAdminDELFDto): Observable<HttpResponse<DemandeDocAdminDELFDto>> {
        return this.http.put<DemandeDocAdminDELFDto>(`${PROCEDURE_SERVICE_PREFIX}/delf-demandes/update/${demande.id}`, demande, {
            observe: 'response'
        });
    }

    getByDemandeDELFById(id: string): Observable<HttpResponse<DemandeDocAdminACCTDto>> {
        return this.http.get<DemandeDocAdminACCTDto>(`${PROCEDURE_SERVICE_PREFIX}/delf-demandes/${id}`, {observe: 'response'});
    }

    checkDocValidity(id: string, type: string): Observable<HttpResponse<CheckValidityData>> {
        return this.http.get<CheckValidityData>(`${PROCEDURE_SERVICE_PREFIX}/traitement/check-doc-validity`,
            {observe: 'response', params: {id, type}});
    }

    verifierTimbre(numeroTimbre: string): Observable<HttpResponse<boolean>> {
        return of(new HttpResponse<boolean>({body: true}));
        //return this.http.get<boolean>(`${PROCEDURE_SERVICE_PREFIX}/verifier-timbre/${numeroTimbre}`, {observe: 'response'});
    }

    getDemandeSrhById(id: string): Observable<HttpResponse<DemandeDocAdminSRHDto>> {
        return this.http.get<DemandeDocAdminSRHDto>(`${PROCEDURE_SERVICE_PREFIX}/srh-demandes/demande-doc-admin/by-userId/${id}`, {observe: 'response'});
    }
}

export const demandeDAResolver: ResolveFn<DemandeDocAdminACCTDto> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    return inject(DemandeAdministratifService).getByDemandeDaById(route.paramMap.get('id'))
        .pipe(
            take(1),
            mergeMap((resp) => {

                if (resp.body) {
                    return of(resp.body);

                } else {
                    return EMPTY;
                }
            })
        );
};


export const demandeDELFResolver: ResolveFn<DemandeDocAdminDELFDto> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    return inject(DemandeAdministratifService).getByDemandeDELFById(route.paramMap.get('id'))
        .pipe(
            take(1),
            mergeMap((resp) => {

                if (resp.body) {
                    return of(resp.body);

                } else {
                    return EMPTY;
                }
            })
        );
};

export const checkDocValidityResolver: ResolveFn<CheckValidityData> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const id = route.queryParamMap.get('key');
    const type = route.queryParamMap.get('type');
    return inject(DemandeAdministratifService).checkDocValidity(id, type)
        .pipe(
            take(1),
            mergeMap((resp) => {

                if (resp.body) {
                    return of(resp.body);
                } else {
                    return EMPTY;
                }
            })
        );
};

<p-blockUI [blocked]="loader">
    <div class="bloked align-items-center">
        <div class="spiner">
            <p-progressSpinner
                styleClass="w-4rem h-4rem"
                strokeWidth="8"
                animationDuration=".5s"/>
        </div>
        <p>Veuillez patienter ...</p>
    </div>
</p-blockUI>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Payment } from './payment.model';
import { PaymentService } from './payment.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
    succes: boolean = false;
    error: boolean = false;
    isLoading: boolean = false;
    title = 'Paiement de service';
    user: any;
    protected readonly history = history;
    payment: Payment;
    callBackUrl = environment.AZERKA_CALLBACK_URL;

    constructor(
        private activatedRoute: ActivatedRoute,
        private paymentService: PaymentService
    ) {
        this.payment = Object.assign({}, this.activatedRoute.snapshot.queryParams) as Payment;
    }


    ngOnInit(): void {
        if (this.payment && (this.payment.status === 'FAILED' || this.payment.status === 'SUCCESS')) {
            if (this.payment.status === 'SUCCESS') {
                this.completePayment();
            } else {
                this.error = true;
            }
        } else {
            this.payment = {
                amount: history.state.amount,
                msisdn: history.state.msisdn,
                demandeId: history.state.demandeId,
                typeDemande: history.state.typeDemande,
                objet: history.state.objet
            };

            localStorage.setItem('payment', JSON.stringify(this.payment));
        }
    }

    intializePayment() {
        if (this.payment.amount > 0) {
            this.paymentService.initPayment(this.payment).subscribe(
                {
                    next: res => {
                        this.doPaymentFromAzerka(res.body.id);
                    }
                }
            );
        } else {
            alert('Impossible de procéder au paiement avec un montant négatif.');
        }
    }

    completePayment() {
        const initPayment = JSON.parse(localStorage.getItem('payment'));
        this.payment = Object.assign(initPayment, this.payment) as Payment;

        this.paymentService.completePayment(this.payment).subscribe(
            {
                next: res => {
                    this.succes = true;
                },
                error: () => {
                    this.error = true;
                }
            }
        );
    }

    doPaymentFromAzerka(mappedOrderId: string) {
        const queryParams = `msisdn=${this.payment.msisdn}&amount=${this.payment.amount}&merchantid=${environment.AZERKA_MERCHANT_ID}&linkBackToCallingWebsite=${btoa(this.callBackUrl)}&mappedOrderId=${mappedOrderId}&securedAccessToken=${environment.AZERKA_SECURED_ACCESS_TOKEN}&linkForUpdateStatus=${btoa(this.callBackUrl)}`;
        const url = `${environment.AZERKA_BASE_URL}/validorder?${queryParams}`;
        window.open(url, '_self');
    }

}

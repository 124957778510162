import { Component } from '@angular/core';
import {FeaturesService} from "../../../features.service";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent {
    loader:boolean;
    constructor(private  featureService:FeaturesService) {
        this.featureService.loader.subscribe(res=>{
            this.loader=res;

        })
    }
    ngOnInit(): void {
    }
}

import { Component } from '@angular/core';
import {FaqService} from "./faq.service";
import {AskQuestion, Faq, isEmptyObject} from "./faq.model";
import {FormBuilder, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss'
})
export class FaqComponent {
    faqs: Array<Faq> = [];
    isRecupFaqs = false;
   asQuestion:AskQuestion={};
    constructor(private  faqService:FaqService) {

    }
ngOnInit(){
this.loadAllFaq();
}

    loadAllFaq() {
        this.isRecupFaqs=true;
        this.faqService.fetchAllFaq()
            .subscribe({
                next: (resp) => {
                    this.isRecupFaqs=false;
                    this.faqs = resp.body || []},
                error: (error) => {

                    this.isRecupFaqs=false;

            }});

    }
    submit(){

        console.log(this.asQuestion);
    }


}

<!-- Banner Area -->

<div id="demo" class="carousel slide" data-bs-ride="auto">
    <!-- Indicators/dots -->
    <div class="carousel-indicators">
        <button type="button" data-bs-target="#demo" data-bs-slide-to="0" class="active"></button>
        <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
        <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
        <button type="button" data-bs-target="#demo" data-bs-slide-to="3"></button>
        <button type="button" data-bs-target="#demo" data-bs-slide-to="4"></button>
    </div>
    <!-- The slideshow/carousel -->
    <div class="carousel-inner">
        <div class="carousel-item active">
            <div    class="banner-area-three1 banner-bg">
                <div class="container">

                    <div class="banner-content banner-content-three ">
                        <h1>Participez aux enquêtes en ligne et déposez vos plaintes et réclamations via LANAYA</h1>
                        <p> Votre opinion compte et peut faire la différence !</p>
                    </div>

                </div>

            </div>
        </div>
        <div class="carousel-item">
            <div    class="banner-area-three3 banner-bg">
                <div class="container">
                    <div class="banner-content banner-content-three ">
                        <h1>Accédez facilement aux documents publics du Trésor grâce à LANAYA </h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="carousel-item">
            <div    class="banner-area-three4 banner-bg">
                <div class="container">
                    <div class="banner-content banner-content-three ">
                        <h1>Suivez vos demandes de paiement en toute simplicité avec LANAYA </h1>
                        <p>Recevez des alertes sur l'état de vos demandes et accédez à tous les détails essentiels </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="carousel-item">
            <div   class="banner-area-three2 banner-bg">
                <div class="container">
                    <div class="banner-content2 banner-content-three ">
                        <h1>LANAYA vous offre une solution simple et rapide pour obtenir les documents administratifs du Trésor  </h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Left and right controls/icons -->
    <button class="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
        <span class="carousel-control-prev-icon"></span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
        <span class="carousel-control-next-icon"></span>
    </button>
</div>

<div class="status-area1">
    <div class="container status-bg1  ">
        <div class="row justify-content-center flex-wrap align-items-center">
            <div class="col-lg-3 col-md-6 status-bg"
                 id="start_item" (click)="onDisplay()">
                <div class="status-card">
                    <a href="javascript:void(0)">
                        <img src="assets/img/icons/1_blanc.png" class="rounded" width="80">
                    </a>
                    <p class="title">Suivi des dossiers des dépenses publiques</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 status-bg"
                 id="middle_item_one" routerLink="/document-administratif/proceduces-dematerialise/all">
                <div class="status-card" >
                    <a routerLink="/document-administratif/proceduces-dematerialise/all">
                        <img src="assets/img/icons/2_blanc.png" class="rounded" width="80">
                    </a>
                    <p class="title">Procédures dématérialisées</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 status-bg" id="middle_item_two"
                 routerLink="/publication">
                <div class="status-card">
                    <a routerLink="/publication">
                        <img src="assets/img/icons/3_blanc.png" class="rounded" width="80">
                    </a>
                    <p class="title">Procédures non dématérialisées</p>

                </div>
            </div>
            <div class="col-lg-3 col-md-6 status-bg" id="last_item"
                 routerLink="/contribution/enquete">
                <div class="status-card">
                    <a routerLink="/contribution/enquete">
                        <img src="assets/img/icons/4_blanc.png" class="rounded" width="80">
                    </a>
                    <p class="title">Contributions</p>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="categorie_procedure container-fluid " id="categorie_procedure" *ngIf="enquetes?.length>0">
    <div class="guidelines-area guidelines-max guidelines-bg2">
        <div class="row">
            <div class="enquete-title mt-3">
                <h2><span>Les nouvelles enquêtes en cours</span></h2>
            </div>

        </div>
        <div class="status-area col-lg-12 col-md-12 col-sm-12">
            <div class="container">
                <p-carousel
                    [value]="enquetes"
                    [numVisible]="3"
                    [numScroll]="3"
                    [circular]="true"
                    autoplayInterval="3000">
                    <ng-template let-enquete pTemplate="item">
                        <div class="services-card-enquete m-3">
                            <a routerLink="/services-details" class="services-icon">
                                <img src="assets/img/icons/4.png" class="rounded" width="40" alt=""></a>
                            <h3><a routerLink="/services-details">{{ enquete.titre }}</a></h3>
                            <div class="description">
                                <p>{{ enquete.description }}</p></div>
                            <a class="services-participe" type="button" (click)="participer(enquete)">Participer</a>
                            <div class="services-card-bottom"></div>
                        </div>
                    </ng-template>
                </p-carousel>
            </div>
        </div></div></div>
<div class="services-area pb-100 pt-80 " style="background-color: #f3f3f9">
    <div class="container">
        <div class="actuality-title mt-3">
            <h2><span>LES SERVICES LES PLUS SOLLICITES</span></h2>
            <p class="text-proper-case"><span>Il s’agit de service dont la procédure d’obtention est dématérialisée</span></p>
        </div>

        <div class="row pb-20" style="margin:20px">
            <div class="col-lg-4 col-md-6" *ngFor="let procedure of proceduresFrequently | slice:0:6">
                <div class="services-card services-card-bg " type="button" [routerLink]="[procedure?.requestUrl]" [queryParams]="{type: procedure?.type}">
                    <a  class="services-icon bg-white">    <img src="assets/img/icons/2.png" width="40" height="40"/></a>
                    <h3><a >{{procedure?.title}}</a></h3>
                    <div class="description">
                    <p>{{procedure?.description}}</p></div>
                    <a [routerLink]="[procedure?.requestUrl]" [queryParams]="{type: procedure?.type}" class="services-more"><i
                            class="flaticon-double-right-arrows-angles"></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>


        <div class="service-view-btn text-center ">
            <a (click)="allProcedure()" type="button" class=" view-btn ">Voir toutes les procédures</a>
        </div>
        </div></div>

    <div class="service-shape">
        <div class="shape1"><img src="assets/img/shape/shape6.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape8.png" alt="Images"></div>
    </div>
</div>
<!-- Services Area End -->

<!-- About Area -->
<div class="about-area pb-20" id="about">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="about-content about-content-max pt-20">
                    <h2>PROCÉDURES NON DÉMATERIALISÉES</h2>
                    <div class="side-bar-widget">
                        <div class="side-bar-categories">
                            <ul class="col-12 grid">

                                @for (publication of publications; track publication) {
                                <div class="doc-public ml-2">
                                    <div class="title-proc">
                                <h3>{{ publication.titre }}</h3>
                                </div>
                                <hr>
                                 <div class="description-doc" >
                                <p>{{publication.description}}</p>

                                </div>

                                <div class="left">
                                <p type="button" (click)="allDowload(publication)" >Télécharger</p>
                                 <img src="assets/img/icons/3.png" width="40" height="40"/>

                                </div>

                                  </div>
                                }
                            </ul>
                            <div class="service-view-btn px-3">
                                <a routerLink="/publication" class="view-btn">Voir tous les documents</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="about-img-3">
                    <img ngSrc="assets/img/section_doc_pub_img.png" alt="Images" height="645" width="645">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About Area End -->

<p-dialog (onHide)="display = false" [modal]="true" [(visible)]="display"
          [style]="{width: '48vw'}" styleClass="ng-dialog">
    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <span class="font-bold white-space-nowrap">
                <span class="col-6">Vérification</span>
                @if (esintaxErrorMessage) {
                    <span class="text-danger col-6">
                        <i class="pi pi-times-circle">&nbsp;</i>
                        {{ esintaxErrorMessage }}
                    </span>
                }
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <form id="contactForm">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <input id="numeroIfu" type="text" [(ngModel)]="searchInfo.numeroIfu"
                               placeholder="Numéro IFU" required minlength="5" name="numeroIfu"
                               class="form-control text-base text-color surface-overlay p-2">
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                        <input id="numeroEsintax" type="text" pKeyFilter="pnum" [(ngModel)]="searchInfo.numeroEsintax"
                               placeholder="Numéro e-SINTAX" required minlength="5" name="numeroEsintax"
                               class="form-control text-base text-color surface-overlay p-2">
                    </div>
                </div>
            </div>
        </form>
    </ng-template>
    <p-footer>
        <button type="button" pRipple pButton icon="pi pi-times" (click)="onDisplay()"
                label="Quitter" class="p-button-secondary p-button-sm position-left mr-3">
        </button>
        <button pRipple pButton type="button" [disabled]="!isValid"
                class="p-button-sm"
                (click)="checkContribuable()" icon="pi pi-send" label="Valider">
        </button>
    </p-footer>
</p-dialog>

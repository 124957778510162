import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CheckPayment, Payment } from './payment.model';
import { PAYMENT_ENDPOINT } from './payment-urls';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    constructor(private http: HttpClient) {
    }

    initPayment(payment: Payment): Observable<HttpResponse<Payment>> {
        return this.http.post<Payment>(PAYMENT_ENDPOINT.INIT_PAYMENT_URL, payment, {observe: 'response'});
    }

    completePayment(payment: Payment): Observable<HttpResponse<Payment>> {
        return this.http.put<Payment>(PAYMENT_ENDPOINT.COMPLETE_PAYMENT_URL, payment, {observe: 'response'});
    }


    checkPayment(mappedOrderId: string): Observable<CheckPayment> {
        return this.http.post<CheckPayment>(PAYMENT_ENDPOINT.CHECK_PAYMENT_URL, null, {
            params: {mappedOrderId},
            headers: {'Authorization': 'Bearer ' + environment.AZERKA_SECURED_ACCESS_TOKEN}
        });
    }

}

import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Message } from 'primeng/api';
import { PieceJointe } from '../pages/publications/publication.model';
import { Router } from '@angular/router';
import { TypePieceJointe } from '../pages/document-administratif/demande-administratif.models';

export const PROCEDURE_SERVICE_PREFIX = '/api/v1/procedure';
export const REFERENTIEL_SERVICE_PREFIX = '/api/v1/referentiel';
export const SUIVI_DOSSIER_SERVICE_PREFIX = '/api/v1/suivi-dossier';

export enum HttpStatusCode {
    error200 = 200,
    error201 = 201,
    error202 = 202,
    error404 = 404,
    error400 = 400,
    error500 = 500,
    error502 = 502,
    error503 = 503
}

export namespace HttpStatusCode {
    const status500List = new Array<HttpStatusCode>(HttpStatusCode.error500, HttpStatusCode.error502, HttpStatusCode.error503);
    const status200List = new Array<HttpStatusCode>(HttpStatusCode.error200, HttpStatusCode.error201, HttpStatusCode.error202);

    export function isError500(statusCode: HttpStatusCode) {
        return status500List.includes(statusCode);
    }

    export function isSuccess200(statusCode: HttpStatusCode) {
        return status200List.includes(statusCode);
    }
}

export interface UserInfos {
    createdById?: string;
    createdAt?: Date;
    updateById?: string;
    UpdateAt?: Date;
}

export function formatUrl(url: string, replaceValue?: string): string {
    if (replaceValue) {
        const searchValue = url.substring(url.indexOf('$'), url.lastIndexOf('$') + 1);

        return url.replace(searchValue, replaceValue);
    }

    return url;
}

export function handleHttpErrors(response: HttpErrorResponse, severity: string, summary: string, key: string): Message {
    const defaultMsg: Message = {
        severity,
        summary,
        detail: 'Une erreur inconnue est survenue, merci de contacter l\'administrateur.',
        key
    };
    if (HttpStatusCode.isError500(response?.error?.status)) {
        return defaultMsg;
    } else {
        if (response.error && response.error.message) {
            return {severity, summary, detail: response.error.message, key};
        } else {
            return defaultMsg;
        }
    }
}

export function handleHttpSuccess(severity: string, summary: string, key: string, message?: string): Message {
    const defaultMessage = `${summary} effectuée avec succès.`;
    return {severity, summary, key, detail: message || defaultMessage};
}


export function onMultiFileUpload(files: File[], pjList: PieceJointe[]) {
    files.forEach(file => {
        const reader = new FileReader();
        reader.onload = () => {
            const result: any = reader.result;

            pjList.push({
                fichier: result.split(',')[1],
                nom: file.name,
                type: file.type
            });

        };
        reader.readAsDataURL(file);
    });
}


export function onFileUpload(file: File, rowdata: any) {
    const reader = new FileReader();
    reader.onload = () => {
        const result: any = reader.result;
        rowdata.fichier = result.split(',')[1];
        rowdata.nom = file.name;
        rowdata.type = file.type;
    };
    reader.readAsDataURL(file);
}

export const createRequestOption = (req?: any): HttpParams => {
    let options: HttpParams = new HttpParams();
    if (req) {
        Object.keys(req).forEach(key => {
            if (key !== 'sort' && key !== 'type' &&
                req[key] !== null && req[key] !== undefined) {
                options = options.set(key, req[key]);
            }
        });
        if (req.sort) {
            req.sort.forEach((val: any) => {
                options = options.append('sort', val);
            });
        }
    }
    return options;
};

export function initLocaleDate(): any {
    return {
        firstDayOfWeek: 1,
        dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
        monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août',
            'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        monthNamesShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jui', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
        today: 'Aujourd\'hui',
        clear: 'Clear',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Wk'
    };
}

export interface TabCols {
    field: string;
    header: string;
    type: string;
    filter?: boolean;
    width?: string;
    centered?: boolean;
    tooltipField?: string;
    skipField?: boolean;
    isMoney?: boolean;
    objectConfig?: any;
}

export const TRANCHAGES = [
    {
        'value': 'INF_18',
        'label': 'Inferieur à 18 ans'
    },
    {
        'value': '_18_25',
        'label': 'Entre 18 et 25 ans'
    },
    {
        'value': '_26_35',
        'label': 'Entre  26 et 35 ans'
    }, {
        'value': '_36_45',
        'label': 'Entre 36 et 45 ans'
    }, {
        'value': '_46_55',
        'label': 'Entre 46 et 55 ans'
    },
    {
        'value': 'SUP_56',
        'label': 'Superieur à 56 ans'
    }


];
export const SEXES = [
    {
        'value': 'MASCULIN',
        'label': 'Homme'
    },
    {
        'value': 'FEMININ',
        'label': 'Femme'
    }, {
        'value': 'AUTRE',
        'label': 'Autre'
    }, {
        'value': 'NE_RIEN_DIRE',
        'label': 'Ne rien dire'
    }
];
export const NIVEAUX = [
    {
        'value': 'NOTE5',
        'label': 'Très satisfaisante'
    },
    {
        'value': 'NOTE4',
        'label': 'Satisfaisante'
    },
    {
        'value': 'NOTE3',
        'label': 'Moyenne satisfaisante'
    },
    {
        'value': 'NOTE2',
        'label': 'Peu satisfaisante'
    },
    {
        'value': 'NOTE1',
        'label': 'Pas du tout satisfaisante'
    }

];
export const RECOMMANNDABLE = [
    {
        'value': 'OUI',
        'label': 'Oui'
    },
    {
        'value': 'NON',
        'label': 'Non'
    }, {
        'value': ' PEUT_ETRE',
        'label': 'Peut-être'
    }
];

export const AcceptFormatDoc = {
    'application/pdf': 'assets/img/pj/pdf_icon.png',
    'application/msword': 'assets/img/pj/words_icon.png',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'assets/img/pj/words_icon.png',
    'application/vnd.ms-excel': 'assets/img/pj/excel_icon.png',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'assets/img/pj/excel_icon.png',
    'application/vnd.ms-powerpoint': 'assets/img/pj/power_icon.png',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'assets/img/pj/power_icon.png',
    'text/csv': 'assets/img/pj/csv_icon.png',
    'application/zip': 'assets/img/pj/zip_icon.png'
};

export function downloadBinaryFile(contentType: any, data: any, fileName: string) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
        type: contentType
    });
    const tempLink = document.createElement('a');
    tempLink.href = window.URL.createObjectURL(blob);
    tempLink.download = fileName;
    tempLink.target = '_blank';
    tempLink.click();
}


export const PLAINTEORRECLAMATION = [
    {
        'value': 'PLAINTE',
        'label': 'Plainte '
    },
    {
        'value': 'RECLAMATION',
        'label': 'Réclamation'
    }


];


export const PRET = [
    {
        'value': 'PRET',
        'label': 'Pret '
    },
    {
        'value': 'AVANCE',
        'label': 'Avance'
    }


];

export const CATEGORIE = [

    'CDT',
    'RAF',
    'RR',
    'RA'
];

export const TYPEBUDGET = [

    'ETAT',
    'COLLECTIVITES',
    'EPE'
];

export interface EnumCautionnement {
    value?: string;
    label?: string;
}

export interface EnumTypeB {
    value?: string;
    label?: string;
}

export enum BtnActions {
    IMPUTATION = 'IMPUTATION',
    VALIDATION = 'VALIDATION',
    SIGNATURE = 'SIGNATURE',
    TRAITEMENT = 'TRAITEMENT',
    REJET = 'TRAITEMENT',
    CLOTURE = 'CLOTURE'
}

export enum StatutDemande {
    ATTENTE = 'EN_ATTENTE',
    EN_COURS = 'EN_COURS',
    TRAITER = 'TRAITER',
    REJETER = 'REJETER',
    RECONDUIRE = 'RECONDUIRE'
}

export interface MotifRejet {
    motif?: string;
    typeMotif?: TypeRejet;
}

export enum TypeRejet {
    ADMINISTRATION = 'ADMINISTRATION',
    CONTRIBUABLE = 'CONTRIBUABLE'
}

export enum CategorieProcedure {
    ACCT = 'ACCT',
    DIT = 'DIT',
    PG = 'PG'
}

export enum EtapeTraitement {
    SOUMISSION = 'SOUMISSION',
    VALIDATESUP = 'VALIDATESUP',
    TRAITEMENT = 'TRAITEMENT',
    VALIDATION_RESPONSABLE = 'VALIDATION_RESPONSABLE',
    EMISSION = 'EMISSION',
    OPERATION = 'OPERATION',
}


export enum EtapeDedouanement {
    ETAPE_SOUMISSION = 'ETAPE_SOUMISSION',
    ETAPE_EMISSION = 'ETAPE_EMISSION',
    ETAPE_OPERATION = 'ETAPE_OPERATION'
}

export function onFileSelected2(event: any, index: number, noSelection: boolean[], pieceJointeMap: Map<string, PieceJointe>, typePieceJointes: Array<TypePieceJointe>) {
    const typePieceId = typePieceJointes[index]?.id;
    event.currentFiles.forEach(file => {
        const newPieceJointe: PieceJointe = {};
        onFileUpload(file, newPieceJointe);
        newPieceJointe.typePieceId = typePieceId;
        noSelection[typePieceId] = false;
        if (file) {
            pieceJointeMap.set(typePieceId, newPieceJointe); // Stocker dans la map par typePieceId
            console.log(`Fichier ajouté pour le typePieceId: ${typePieceId}`);
        }
    });
}

export function onFileSelected(event: any, index: number) {
    const pieceName = Object.keys(this.documentLibellesMap)[index];
    event.currentFiles.forEach(file => {
        const newPieceJointe: PieceJointe = {};
        onFileUpload(file, newPieceJointe);
        newPieceJointe.libellePiece = this.documentLibellesMap[pieceName] || '';
        this.noSelection[pieceName] = false;

        if (file) {
            this.pieceJointeMap.set(pieceName, newPieceJointe);
            console.log();
        }
    });
}

export function removePieceJointe2(index: number, noSelection: boolean[], pieceJointeMap: Map<string, PieceJointe>, typePieceJointes: Array<TypePieceJointe>): void {
    const typePieceId = typePieceJointes[index]?.id;
    if (pieceJointeMap.has(typePieceId)) {
        // Supprimer la pièce jointe du map
        pieceJointeMap.delete(typePieceId);
        noSelection[typePieceId] = true;
    }
}

export function removePieceJointe(index: number): void {
    const typePieceId = this.typePiecesJointe[index]?.id;
    if (this.pieceJointeMap.has(typePieceId)) {
        // Supprimer la pièce jointe du map
        this.pieceJointeMap.delete(typePieceId);
        this.noSelection[typePieceId] = true;
    }
}

//Check if a file is required
export function isPieceObligatoire(pieceId: string, typePieceJointes: Array<TypePieceJointe>): boolean {
    //const pieceId = documentLibellesMap[libelle];
    const piece = typePieceJointes.find(p => p.id === pieceId);
    return piece ? piece.obligatoire : false;
}

//disable buttons if required files arent uploaded
export function areAllRequiredPiecesUploaded(pieceJointeMap: Map<string, PieceJointe>, typePieceJointes: Array<TypePieceJointe>, documentLibellesMap: {}): boolean {
    return Object.keys(documentLibellesMap).every(libelle => {
        const pieceId = documentLibellesMap[libelle];
        const piece = typePieceJointes.find(p => p.id === pieceId);
        if (piece?.obligatoire) {
            return pieceJointeMap.has(pieceId);
        }
        return true;
    });
}


export function initializeFiles(files: PieceJointe[], noSelection: boolean[], pieceJointeMap: Map<string, PieceJointe>, documentLibellesMap: {}) {
    console.log(files);
    if (files) {
        files.forEach(pieceJointe => {
            const libelle = Object.keys(documentLibellesMap)
                .find(key => documentLibellesMap[key] === pieceJointe.typePieceId);
            if (libelle) {
                pieceJointeMap.set(pieceJointe.typePieceId, pieceJointe);
                noSelection[pieceJointe.typePieceId] = true;
            }
        });
    }
}

export function handleSuccessOrError(state: {
    succes: boolean,
    error: boolean
}, isEditMode: boolean, router: Router, routeOnSuccess: string): void {
    if (state.succes) {
        state.succes = false;
        state.error = false;
        router.navigate(isEditMode ? ['/espace-utilisateur/demandes'] : ['/document-administratif' + routeOnSuccess]);
    } else {
        state.succes = false;
        state.error = false;
    }
}

export enum EXCENDENT_TROP_PERCU {
    EXCEDENT = 'EXCEDENT DE VERSEMENT',
    TROP_PERCU = 'TROP_PERCU'
}


// Convert the date arrays to strings
export function formaterDate(date: string | number[]) {
    if (Array.isArray(date)) {
        const [year, month, day] = date;
        return new Date(year, month - 1, day); // Create a Date object (months are 0-indexed)
    }
    return new Date(date); // Convert string to Date object
}

import { PROCEDURE_SERVICE_PREFIX } from '../../utils/app-utils';
import { environment } from '../../../../environments/environment';

export class PAYMENT_ENDPOINT {
    static readonly PAYMENT_URL = `${PROCEDURE_SERVICE_PREFIX}/traitement`;
    static readonly INIT_PAYMENT_URL = `${PAYMENT_ENDPOINT.PAYMENT_URL}/init-payment`;
    static readonly COMPLETE_PAYMENT_URL = `${PAYMENT_ENDPOINT.PAYMENT_URL}/complete-payment`;
    static readonly CHECK_PAYMENT_URL = `${environment.AZERKA_BASE_URL}/getThirdPartyMapInfo`;
}


import { PieceJointe } from '../publications/publication.model';
import {
    BtnActions,
    EtapeDedouanement,
    EtapeTraitement,
    MotifRejet,
    StatutDemande,
    UserInfos
} from '../../utils/app-utils';
import { DetailAgent } from './demande-dit/demande-dit.data';
import { OrdreMissionAgentPublicDto } from './demande-bcms/demande-bcms.data';

export interface Procedure {
    title?: string;
    description?: string;
    type?: string,
    delai?: string;
    cout?: string;
    pieceAfournir?: string[];
    docResponsable?: string;
    requestUrl?: string,
    serviceLibelleLong?: string;
    serviceLibelleCourt?: string;
}

export enum TypeDemande {
    CODE_LOGICIEL = 'CODE_LOGICIEL',
    REATRIBUTION = 'REATRIBUTION',
    CREATION = 'CREATION',
    RETRAIT = 'RETRAIT',
    PRET_AVANCE = 'PRET_AVANCE',
    CONSTITUTION_CAUTION = 'CONSTITUTION_CAUTION',
    REMBOURSEMENT_PRET = 'REMBOURSEMENT_PRET',
    SOLDE_COMPTE = 'SOLDE_COMPTE',
    COMPTE_SERVICE = 'COMPTE_SERVICE',
    CAUTIONNEMENT = 'CAUTIONNEMENT',
    TROP_PERCU = 'TROP_PERCU',
    REMBOURSEMENT_CAUTIONNEMENT = 'CAUTION_CONSIGNATION',
    DEMANDE_REGULARISATION_REJET = 'DEMANDE_REGULARISATION_REJET',
    DEMANDE_DEFICIT_DEBET = 'DEFICIT_DEBET',
    DEMANDE_REMBOURSEMENT_CAUTION = 'DEMANDE_REMBOURSEMENT_CAUTION',
    DEMANDE_ENCAISSEMENT_EFFECTIF = 'DEMANDE_ENCAISSEMENT_EFFECTIF',
    VIREMENT_BANCAIRE = 'VIREMENT_BANCAIRE',
    DEMANDE_CERTIF_TRAVAIL = 'DEMANDE_CERTIF_TRAVAIL',
    DEMANDE_INVESTISSEMENT_ETRANGER = 'DEMANDE_INVESTISSEMENT_ETRANGER',
    DECLARATION_AGENT = 'DECLARATION_AGENT',
    DEMANDE_ATTEST_TRAVAIL = 'DEMANDE_ATTEST_TRAVAIL',
    DEMANDE_EXPLOI_CASINO = 'DEMANDE_EXPLOI_CASINO',
    DEMANDE_AUTO_OUVER_CASINO = 'DEMANDE_AUTO_OUVER_CASINO',
    DEMANDE_EXPLO_MACHINE_SOUS = 'DEMANDE_EXPLO_MACHINE_SOUS',
    DEMANDE_OUVER_SALLE_MACHINE_SOUS = ' DEMANDE_OUVER_SALLE_MACHINE_SOUS',
    DEMANDE_ETABLISSEMENT_CARTE = 'DEMANDE_ETABLISSEMENT_CARTE',

    DEMANDE_LOTERIES_TOMBOLAS = 'DEMANDE_LOTERIES_TOMBOLAS',
    DEMANDE_AGREMENT_COURTIER = 'DEMANDE_AGREMENT_COURTIER',
    RECLAMATION_SINISTRE = 'RECLAMATION_SINISTRE',
    RECRUTEMENET_CARDE = 'RECRUTEMENET_CARDE',
    DEMANDE_ORDRE_MISSION = 'DEMANDE_ORDRE_MISSION',
    SOUSCRIPTION_ENGAGEMENT = 'SOUSCRIPTION_ENGAGEMENT',
    LIBERATION_CAUTIONNEMENT = 'LIBERATION_CAUTIONNEMENT',
    AUTORISATION_CHANGE_MANUEL = 'AUTORISATION_CHANGE_MANUEL',
    DELEGATION_SOLDE = 'DELEGATION_SOLDE',
    DEMANDE_ATTESTATION_REVERSEMENT = 'DEMANDE_ATTESTATION_REVERSEMENT',
    AVANCE_CREDIT_DEDOUANEMENT = 'AVANCE_CREDIT_DEDOUANEMENT',
    AUTO_GESTION_FOND_MALADIE = 'AUTO_GESTION_FOND_MALADIE',
    DEMANDE_AGREMENT_EXTENSION = 'DEMANDE_AGREMENT_EXTENSION',
    INSCRIPTION_CONCOURS = 'INSCRIPTION_CONCOURS',
}


export interface InscriptionConcours extends UserInfos {
    id: string;
    idConcours: string;
    nom: string;
    prenom: string;
    email: string;
    numeroQuittance: string;
    numero: string;
    pieceJointes: PieceJointe[];
    alreadyPaid: boolean;
}

export interface DemandeDocAdminACCTDto {
    id: string;
    validate: boolean;
    typeDemande: TypeDemande;
    typeSituation: string;
    objet: string;
    numero: string;
    annee: string;
    numeroCompte?: string;
    referencesAct?: string;
    dateActe: string;
    montant?: number;
    montantCautionne?: number;
    resteCautionne?: number;
    identiteDemandeur: string;
    modaliteRemboursement: string;
    ville: string;
    datePayement: string;
    referenceLettre?: string;
    pieceJointes: PieceJointe[];
    structureId?: string;
    listeDebetsDeficit: Debet[];
    etapeTraitement?: BtnActions;
    motifsRejet?: MotifRejet[];
    statut?: StatutDemande;
    matriculeDemandeur?: string;
    nature?: string;
    typeCompte?: string;
    createdAt: string;
    ifu?: string;
    rccm?: string;
    nomSociete?: string;
    statutSociete?: String;
    nomBanque?: string;
    rejeter?: boolean;
    motifEncaissement?: string;
    observations?: Observation[];
    observation?: string;
    reconduite?: boolean;
    allFileZip?: PieceJointe;
    observationSend?: boolean;
    dateMin: string;
    dateMax: string;
    dateCompensation?: string;
    categorie?: string;
    nomCompte?: string;
    posteComptable?: string;
    refPV?: string;
    datePretAvance?: string;
    numeroEtimbre?: string;
}

export interface DemandeDocAdminDamofDto {
    id: string;
    typeDemande: TypeDemande;
    typeSituation: string;
    objet: string;
    numero: string;
    numeroCompte?: string;
    referencesAct?: string;
    dateActe: string;
    montant?: number;
    montantCautionne?: number;
    resteCautionne?: number;
    identiteDemandeur: string;
    modaliteRemboursement: string;
    ville: string;
    datePayement?: string;
    referenceLettre?: string;
    pieceJointes: PieceJointe[];
    structureId?: string;
    numeroEtimbre?: string;
    listeDebetsDeficit: Debet[];
    etapeTraitement?: EtapeTraitement;
    motifsRejet?: MotifRejet[];
    statut?: StatutDemande;
    matriculeDemandeur?: string;
    nature?: string;
    nomBanque?: string;
    motifEncaissement?: string;
    createdAt?: string;
    updatedAt?: string;
    identiteStructure?: string;
    identitePersonne?: string;
    ifu?: string;
    rccm?: string;
    localisationLieu?: string;


}


export interface Structure {
    id?: string;
    libelleCourt?: string;
    libelleLong?: string;
    description?: string;
    directionId?: string;
    libelleDirection?: string;
    typeStructure?: TypeStructure;
    timbre?: Timbre;
}

export interface Timbre {
    id?: string;
    ministere?: string;
    secretariat?: string;
    directionGenerale?: string;
    direction?: string;
    service?: string;
    structureId?: string;
}

export enum TypeStructure {
    DIRECTION = 'DIRECTION',
    SERVICE = 'SERVICE'
}


export class Debet {
    constructor(
        public dateDebet?: String,
        public montant?: String,
        public reference?: String,
        public observation?: String
    ) {
    }
}

export enum TypeCompte {
    Bancaire = 'Bancaire',
    Beneficiaire = 'Beneficiaire'
}

export enum TypeSituation {
    PRET = 'PRET',
    AVANCE = 'AVANCE',
    DEFICIT = 'DEFICIT',
    DEBET = 'DEBET',
    CAUTION = 'CAUTION',
    CONSIGNATION = 'CONSIGNATION',
}


export interface DemandeCodeLogiciel extends UserInfos {
    id?: string;
    typeDemande?: TypeDemande;
    objet?: string;
    ville?: string;
    numero?: string;
    structureValidateurId?: string;
    referenceLettre?: string;
    signataire?: string;
    titreHonorifique?: string;
    idDemande?: string;
    processing?: boolean;
    etapeTraitement?: EtapeTraitement;
    statut?: string;
    ValidateSup?: boolean;
    structureValidateurLibelle?: string;
    agentPublics?: Array<DetailAgent>;

}

export interface AgentPublic {
    id?: string;
    key?: number;
    code?: string;
    ancienUtilisateur?: string;
    nouveauUtilisateur?: string;
    matricule?: string;
    structure?: string;
    profile?: string;
}

export class Logiciel {
    id?: string;
    libelle?: string;
    typeLogiciel?: string;
}

export class Profil {
    id?: string;
    libelle?: string;

}

export interface TypePieceJointe {
    hasFile: any;
    id?: string;
    libelle?: string;
    typeSituation?: TypeSituation;
    etapeDedouanement?: EtapeDedouanement;
    obligatoire?: boolean;
    typeDemande?: TypeDemande;
}

export interface DemandeDocAdminSRHDto {
    id: string;
    numeroEtimbre: string;
    typeDemande: TypeDemande;
    fonction?: string;
    objet: string;
    dateActe: string;
    dateDebut: string;
    dateFin: string;
    identiteDemandeur: string;
    pieceJointes: PieceJointe[];
    statut?: StatutDemande;
    matriculeDemandeur?: string;
    etapeTraitement?: BtnActions;
    motifsRejet?: MotifRejet[];
}

export interface DemandeDocAdminDELFDto {
    id: string;
    typeDemande: TypeDemande;
    objet: string;
    pieceJointes: PieceJointe[];
    statut?: StatutDemande;
    matriculeDemandeur?: string;
    etapeTraitement?: BtnActions;
    motifsRejet?: MotifRejet[];
    numeroEtimbre?: string;


}

export interface DemandeDocAdminBcmsDto {
    id?: string;
    numero: string;
    typeDemande: TypeDemande;
    objet: string;
    motif: string;
    structureValidation: string;
    agentsPublics: Array<OrdreMissionAgentPublicDto>;
    date_depart: string;
    date_retour: string;
    lieu: string[];
    moyen_transport: string;
    immatriculation_vehicule: string;
    budget: string;
    frais_mission: string;
    pieceJointes?: PieceJointe[];
    etapeTraitement?: EtapeTraitement;
    motifsRejet?: MotifRejet[];
    statut?: StatutDemande;
    createdAt?: string;
    updatedAt?: string;
    idChefMission?: string;
}


export interface DemandeDocAdminRGDto {
    id: string;
    numero: string;
    montantAvance: number;
    genre_vehicule: string;
    marque_vehicule: string;
    type_vehicule: string;
    numero_immatriculation: string;
    montantVersement: number;
    ordre_recette?: string;
    matriculeDemandeur: string;
    identiteDemandeur: string;
    pieceJointes: PieceJointe[];
    typeDemande: TypeDemande;
    objet: string;
    etapeTraitement?: EtapeTraitement;
    motifsRejet?: MotifRejet[];
    statut?: StatutDemande;
    createdAt?: string;
    updatedAt?: string;
    numeroEtimbre?: string;
    // Remboursements
    mois_debut: string;
    mois_fin: string;
    montantMensuel: number;
    acompte: number;

    // Eléments du bulletin de liquidation douane
    droitDeDouane?: number;
    taxeStatImport?: number;
    tva?: number;
    tvt?: number;
    peage?: number;
    pcs?: number;
    pc?: number;
    ri?: number;
    rs?: number;
    air?: number;
    ts?: number;
    fsp?: number;
    totalGeneral?: number;

    // Eléments du bulletin de paie
    soldeIndiciaire?: number;
    indemniteResid?: number;
    retenue?: number;

    quotiteCessible?: number;
    montantDuCredit?: number;
    interets?: number;
    precompte?: number;

    debutPrecompte?: string;
    finPrecompte?: string;

    nombreDeMois?: number;
    avance?: number;
    alreadyPaid?: boolean;
}

export interface DemandeDocAdminTMDCDto {
    id: string;
    numero: string;
    identiteDemandeur: string;
    matricule: string;
    pieceJointes: PieceJointe[];
    typeDemande: TypeDemande;
    objet: string;
    numeroEtimbre: string;
    etapeTraitement?: EtapeTraitement;
    motifsRejet?: MotifRejet[];
    statut?: StatutDemande;
    createdAt?: string;
    updatedAt?: string;
}

export interface DemandeDocAdminITTDto {
    id: string;
    numero: string;
    montantLiberation: number;
    identiteDemandeur: string;
    timbre: string;
    typeLiberation: string;
    pieceJointes: PieceJointe[];
    typeDemande: TypeDemande;
    objet: string;
    etapeTraitement?: EtapeTraitement;
    motifsRejet?: MotifRejet[];
    statut?: StatutDemande;
    createdAt?: string;
    updatedAt?: string;
    numeroEtimbre?: string;
    // Champs ajoutés
    structureVerification: string;
    lieuMission: string;
    dateValidation: string;
    dateInstallation: string;
    typeFiche: string;
    nomComptable: string;
    matriculeComptable: string;
    fonctionComptable: string;
    lieuExercice: string;
    montantCautionnement: number;
    etatCautionnement: string;
    modeConstitution: string;
    montantPeriodique: number;
    periode: string;
    titreHonorifique: string;
}

export interface Observation extends UserInfos {
    observation?: string;
    etapeTraitement?: EtapeTraitement;
    active?: boolean;
    pieceJointes?: PieceJointe[];

}

export interface ActionnaireDto {
    id?: string;
    identite?: string;
    poste?: string;
    partSociete?: string;
    nationalite?: string;
    pieceJointes?: PieceJointe[];
}


export interface Concours {
    id?: string;
    dateOuverture?: string;
    dateFermeture?: string;
    statut: StatusConcour;
    pieceJointeList: PieceJointe[];
    libelle: string;
}

export enum StatusConcour {
    ENATTENTE = 'ENATTENTE',
    DEPOT = 'DEPOT',
    PRESELECTION = 'PRESELECTION',
    ADMINISTRATION = 'ADMINISTRATION',
    FERME = 'FERME'
}

export enum TypeLogiciel {
    INTERNE = 'INTERNE',
    EXTERNE = 'EXTERNE',
}

export interface CheckValidityData {
    numeroDemande?: string;
    dateDemande?: string;
}

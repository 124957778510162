import { Component, OnInit } from '@angular/core';
import {AskQuestion} from "../faq/faq.model";
import {ContactService} from "./contact.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    asQuestion:AskQuestion={};
    isLoading: boolean = false;
    succes: boolean = false;
    error: boolean = false;

    constructor(private contactService:ContactService,private router: Router) { }

  ngOnInit(): void {
  }
    submit(){


        this.isLoading = true;

        this.contactService.sendContactResponseToAdmin(this.asQuestion).subscribe(
            () => {
                this.isLoading = false;
                this.succes = true;
                this.error = false;

            },
            error => {
                console.error('Error sending email:', error);
                this.succes = false;
                this.error = true;

            }
        );
    }

    merci() {
        if (this.succes) {
            this.succes = false;
            this.error = false;
            this.router.navigate(['/contact']);
        } else {
            this.succes = false;
            this.error = false;

        }

    }
}

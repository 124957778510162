// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// @ts-ignore
import packageInfo from '../../package.json';

export const environment = {
    production: false,
    VERSION: packageInfo.version,
    lanaya_dgi_esintax_url: 'http://lanaya.dgiburkina.com:8080/api/contribuables',

    AZERKA_MERCHANT_ID: 1,
    AZERKA_SECURED_ACCESS_TOKEN: 'eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiI5MlcyVkw5MzE0IiwiaWF0IjoxNzI2NTcyOTA4LCJzdWIiOiIyMjYwMDAwMDAzMSIsImlzcyI6ImFyemVrYSIsIlBBWUxPQUQiOiJhY2Nlc3NfdG9rZW4iLCJleHAiOjE3ODk2NDQ5MDh9.WZaHInLVsGzaknBKC37FMiLV8sQxQaVebGHbVNH0gWEop2S96Zh8qdaCyS1KILea2o0TOrWtKEExiJVsichxcQ',
    AZERKA_BASE_URL: 'https://pgw-test.fasoarzeka.bf/AvepayPaymentGatewayUI/avepay-payment/app',
    AZERKA_CALLBACK_URL: 'http://localhost:9000/payment'
};

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {FaqEndpoint} from "./faq.urls";
import {Faq} from "./faq.model";


@Injectable({providedIn: 'root'})
export class FaqService {

    constructor(private http: HttpClient) {
    }

    public fetchAllFaq(): Observable<HttpResponse<Array<Faq>>> {
        return this.http.get<Array<Faq>>(`${FaqEndpoint.FAQ_ALL_URL}`, {observe: 'response'});
    }


}

<div class="inner-banner inner-bg2">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{ title }}</h3>
        </div>
    </div>
</div>

<section class="demande" style="padding-left:4%;padding-right:5%;padding-bottom:2%">
    <div id="msform" class="card-merci" *ngIf="succes || error">
        <div style="text-align: center">
            <img src="assets/img/succes.jpeg" *ngIf="succes && !error" width="200" alt="Success Image">
            <img src="assets/img/error.jpeg" *ngIf="!succes && error" width="200" alt="Error Image">
        </div>
        <div class="col-12">
            <h2 *ngIf="succes && !error">Paiement effectué avec succès</h2>
            <h2 *ngIf="!succes && error">Une erreur s'est produite !<br>Veuillez réessayer</h2>
        </div>
        <div style="text-align: center">
            <button pButton pRipple label="Retour" icon="pi pi-arrow-left"
                    [routerLink]="['/espace-utilisateur/demandes']"
                    class="previous action-button-previous">
            </button>
        </div>
    </div>

    <div *ngIf="!succes && !error">
        <form #editForm="ngForm" autocomplete="off" role="form">
            <div class="grid p-fluid">
                <div class="field col-6">
                    <label for="msisdn" class="font-bold text-required">Numéro</label>
                    <input id="msisdn" type="text" pInputText [(ngModel)]="payment.msisdn"
                           class="my-2 w-full" name="msisdn"/>
                </div>
                <div class="field col-6">
                    <label for="amount" class="font-bold text-required">Montant</label>
                    <input id="amount" type="text" mask="separator" pInputText [(ngModel)]="payment.amount"
                           class="my-2 w-full" readonly name="amount"/>
                </div>
            </div>
        </form>

        <div class="flex justify-content-center flex-wrap">
            <button pButton pRipple label="Effectuer le Paiement" icon="pi pi-send"
                    (click)="intializePayment()"
                    [disabled]="editForm?.invalid || isLoading"
                    class="p-button-primary contact-btn"></button>
        </div>
    </div>
</section>

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();

    /**
     * This function overwrites the `console.log` method to prevent any logs from being displayed.
     * This is useful in production environments to prevent sensitive information from being exposed.
     */
    window.console.log = () => {
        // This empty arrow function does nothing, effectively preventing any logs from being displayed.
    };
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));

import { TypeDemande } from './demande-administratif.models';

export const PROCEDURE_ACCT = [
    {
        title: 'Situation de cautionnement',
        description: 'Une demande de situation de cautionnement est un document ou une démarche effectuée pour obtenir des informations sur l\'état actuel d\'une caution ou d\'un garant.',
        type: TypeDemande.CAUTIONNEMENT,
        delai: '3',
        cout: 'Gratuit',
        pieceAfournir: ['Aucune pièce à fournir'], // TODO a récupérer en BD au chargement de la page soumission, TypePieceJointe
        requestUrl: '/document-administratif/soumission/situation-cautionnement',
        serviceLibelleLong: 'L\'Agence Comptable Centrale du Trésor',
        serviceLibelleCourt: 'acct',
        loadAsFrequently: true
    },
    {
        title: 'Attestation de compte des services non personnalisés',
        description: 'Permet obtenir un document certifiant le solde des comptes de services non personnalisés pour lesquels le demandeur est bénéficiaire ',
        type: TypeDemande.COMPTE_SERVICE,
        delai: '1',
        cout: 'Gratuit',
        pieceAfournir: [],
        requestUrl: '/document-administratif/soumission/compte-service',
        serviceLibelleLong: 'L\'Agence Comptable Centrale du Trésor',
        serviceLibelleCourt: 'acct',
        loadAsFrequently: true
    },

    {
        title: 'Attestation de remboursement de prêt',
        description: 'Purus mattis mi, libero maecenas volutpat quis a morbi arcu pharetra, mollis',
        type: TypeDemande.REMBOURSEMENT_PRET,
        delai: '1',
        cout: 'Gratuit',
        pieceAfournir: [],
        requestUrl: '/document-administratif/soumission/remboursement-pret',
        serviceLibelleLong: 'L\'Agence Comptable Centrale du Trésor',
        serviceLibelleCourt: 'acct',
        loadAsFrequently: true
    },
    {
        title: 'Attestation de soldes des  compte des services non personnalisés',
        description: 'Permet obtenir un document certifiant le solde des comptes de services non personnalisés pour lesquels le demandeur est bénéficiaire ',
        type: TypeDemande.SOLDE_COMPTE,
        delai: '1',
        cout: 'Gratuit',
        pieceAfournir: [],
        serviceLibelleLong: 'L\'Agence Comptable Centrale du Trésor',
        requestUrl: '/document-administratif/soumission/compte-solde',
        serviceLibelleCourt: 'acct',
        loadAsFrequently: true
    },

    {
        title: 'Situation de déficit ou de débet',
        description: 'Cette demande est souvent réalisée par des analystes financiers, des gestionnaires de comptes ou des auditeurs internes pour évaluer les risques, les besoins de financement ou les performances financières.',
        type: TypeDemande.DEMANDE_DEFICIT_DEBET,
        delai: '4',
        cout: 'Gratuit',
        pieceAfournir: [],
        requestUrl: '/document-administratif/soumission/deficit-debet/',
        serviceLibelleCourt: 'acct',
        serviceLibelleLong: 'L\'Agence Comptable Centrale du Trésor',
        loadAsFrequently: true
    },
    {
        title: 'Régularisation de rejet de virement',
        description: 'Cette demande est souvent adressée par un responsable financier ou un comptable à la banque ou au service concerné pour résoudre les problèmes liés au virement rejeté',
        type: TypeDemande.DEMANDE_REGULARISATION_REJET,
        delai: '1',
        cout: 'Gratuit',
        pieceAfournir: ['Attestation de cloture de compte', 'Bon RIB'],
        serviceLibelleLong: 'L\'Agence Comptable Centrale du Trésor',
        serviceLibelleCourt: 'acct',
        requestUrl: '/document-administratif/soumission/regularisation-rejet-virement',
        loadAsFrequently: true

    },
    {
        title: 'Remboursement de caution et consignation',
        description: 'Cette demande est adressée à l\'entité responsable de la gestion de la caution pour obtenir le retour des fonds après que les conditions contractuelles ou légales pour lesquelles la caution a été versée ont été remplies.',
        type: TypeDemande.REMBOURSEMENT_CAUTIONNEMENT,
        delai: '4',
        cout: 'Gratuit',
        pieceAfournir: ['Document autorisant la liberation'],
        serviceLibelleLong: 'L\'Agence Comptable Centrale du Trésor',
        serviceLibelleCourt: 'acct',
        requestUrl: '/document-administratif/soumission/caution-consignation',
        loadAsFrequently: true
    },
    {
        title: 'Remboursement de trop perçu ou excédent de versement ',
        description: 'Une demande de remboursement de trop-perçu ou dexcédent ',
        type: TypeDemande.TROP_PERCU,
        delai: '3',
        cout: 'Gratuit',
        requestUrl: '/document-administratif/soumission/trop-perçu',
        pieceAfournir: ['Fiche d\'opposition', 'Bulletin de salaire', 'Fiche de synthèse', 'Avis de versement', 'RIB(si remourssement par virement)'],
        serviceLibelleLong: 'L\'Agence Comptable Centrale du Trésor',
        serviceLibelleCourt: 'acct',
        loadAsFrequently: true
    },

    {
        title: 'Attestation de constitution de cautionnement  (Vente d\'or…)',
        description: 'Permet obtenir un document certifiant la constitution de cautionnement pour lesquels le demandeur est bénéficiaire',
        type: TypeDemande.CONSTITUTION_CAUTION,
        delai: '3',
        cout: 'Gratuit',
        pieceAfournir: [],
        requestUrl: '/document-administratif/soumission/constitution',
        serviceLibelleLong: 'L\'Agence Comptable Centrale du Trésor',
        serviceLibelleCourt: 'acct',
        loadAsFrequently: true
    },
    {
        title: 'Situation des prêts et avances ',
        description: 'Permet obtenir un document certifiant la situation des prêts et avances pour lesquels le demandeur est bénéficiaire',
        type: TypeDemande.PRET_AVANCE,
        delai: '3',
        cout: 'Gratuit',
        pieceAfournir: [
            'Une convention prêt',
            'Une lettre d\'habilitation',
            'Une convention avance',
            'Une lettre accréditation'
        ],
        requestUrl: '/document-administratif/soumission/situtation-pret-avance',
        serviceLibelleLong: 'L\'Agence Comptable Centrale du Trésor',
        serviceLibelleCourt: 'acct',
        loadAsFrequently: true
    },
    {
        title: 'Attestation d\'encaissement effectif ',
        description: 'Permet obtenir un document certifiant la situation des prêts et avances pour lesquels le demandeur est bénéficiaire',
        type: TypeDemande.DEMANDE_ENCAISSEMENT_EFFECTIF,
        delai: '3',
        cout: 'Gratuit',
        pieceAfournir: ['Déclaration de perte de quittance', 'Copie de la quittance égarée'],
        requestUrl: '/document-administratif/soumission/demande-encaissement-effectif',
        serviceLibelleLong: 'L\'Agence Comptable Centrale du Trésor',
        serviceLibelleCourt: 'acct',
        loadAsFrequently: true
    },

    {
        title: 'Avis de transfert',
        description: 'Permet d\'obtenir une information sur un virement bancaire. Cette demande peut être faite par la banque ou le bénéficiaire du virement',
        type: TypeDemande.VIREMENT_BANCAIRE,
        delai: '1',
        cout: 'Gratuit',
        pieceAfournir: [],
        requestUrl: '/document-administratif/soumission/demande-virement-bancaire',
        serviceLibelleLong: 'L\'Agence Comptable Centrale du Trésor',
        serviceLibelleCourt: 'acct',
        loadAsFrequently: true
    }

];


export const PROCEDURE_DIT = [
    {
        title: 'Code d\'accès à un logiciel interne ou externe à la DGTCP',
        description: 'Cette procédure vous permet de faire une demande création de code d\'accès à un logiciel interne ou externe.',
        type: TypeDemande.CREATION,
        delai: '3',
        cout: 'Gratuit',
        requestUrl: '/document-administratif/soumission/creation-code-logiciel',
        serviceLibelleLong: 'La Direction de l\'information du Trésor ',
        serviceLibelleCourt: 'dit',
        loadAsFrequently: true
    }


];

export const PROCEDURE_SRH = [
    {
        title: 'Certificat de travail',
        description: 'Cette procédure vous permet de faire une demande de certificat de travail.',
        type: TypeDemande.DEMANDE_CERTIF_TRAVAIL,
        delai: '3',
        cout: 'Gratuit',
        pieceAfournir: ['une photocopie de la prise de service',
            'un certificat de cessation de service', 'une quittance de droit de timbre'],
        requestUrl: '/document-administratif/soumission/demande-certif-travail',
        serviceLibelleLong: 'Le Service des Ressources Humaines ',
        serviceLibelleCourt: 'srh',
        loadAsFrequently: true
    },
    {
        title: 'Attestation de travail',
        description: 'Cette procédure vous permet de faire une demande d\'attestation de travail.',
        type: TypeDemande.DEMANDE_ATTEST_TRAVAIL,
        delai: '3',
        cout: 'Gratuit',
        pieceAfournir: ['une photocopie de la prise de service'],
        requestUrl: '/document-administratif/soumission/demande-attest-travail',
        serviceLibelleLong: 'Le Service des Ressources Humaines ',
        serviceLibelleCourt: 'srh',
        loadAsFrequently: true
    }
];

export const PROCEDURE_DELF = [
    {
        title: 'Licence d\'exploitation de Casino',
        description: 'Cette procédure vous permet de faire une demande de licence d\'exploitation de casino.',
        type: TypeDemande.DEMANDE_EXPLOI_CASINO,
        delai: '3',
        cout: 'Gratuit',
        pieceAfournir: [
            'Une demande d\'autorisation ou de licence d’exploitation d’un casino',
            'Une quittance de droit de timbre spécial de cinq millions (5 000 000) de francs CFA',
            'Un procès-verbal de l’assemblée générale constitutive',
            'Le statut de la société',
            'L’état des associés',
            'La liste des administrateurs et celle des commissaires aux comptes',
            'Un extrait du registre du commerce et de crédit mobilier',
            'Une autorisation du maire de la commune concernée',
            'Les attestations de situation fiscale, de la Caisse Nationale de Sécurité Sociale et de l’Agence Judiciaire du Trésor',
            'Une copie des titres d’occupation des locaux',
            'Un contrat de bail ou une autorisation du bailleur ',
            'Le plan détaillé de l\'établissement en deux exemplaires',
            'Une documentation technique décrivant les références techniques des appareils et matériels à utiliser et les règles de fonctionnement des jeux',
            'Un mémorandum',
            'Le plan de développement prévisionnel de l’établissement de jeu sur cinq (05) ans',
            ' un état des propositions relatives au niveau des mises ',
            'Un engagement à verser un cautionnement',
            'Un extrait d\'acte de naissance',
            'Un extrait de casier judiciaire',
            'Un certificat de nationalité',
            'Un curriculum vitae en deux (02) exemplaires',
            'Trois (03) photos d\'identité récentes',
            'Les actes de nomination'
        ],
        requestUrl: '/document-administratif/soumission/demande-Licence-exploitation-Casino',
        serviceLibelleLong: 'La Direction des Etudes et de la Législation Financière  ',
        serviceLibelleCourt: 'delf',
        loadAsFrequently: true
    },
    {
        title: 'Autorisation d\'ouverture de salles de casinos',
        description: '',
        type: TypeDemande.DEMANDE_AUTO_OUVER_CASINO,
        delai: '3',
        cout: 'Gratuit',
        requestUrl: '/document-administratif/soumission/demande-autorisation-ouverture-salles-casinos',
        serviceLibelleLong: 'La Direction des Etudes et de la Législation Financière',
        serviceLibelleCourt: 'delf',
        pieceAfournir: [
            // 'Une demande  munie d\'une copie de la quittance du droit de timbre spécial d\'un million (1 000 000) de francs CFA',
            // 'L\'avis du Maire de la commune d\'implantation',
            // 'Une copie de la licence d\'exploitation des casinos',
            // 'Une copie de la quittance de versement de la caution bancaire',
            // 'Une évaluation des investissements réalisés et à réaliser ',
            // 'Une copie des titres d\'occupation des locaux',
            // 'Un état des machines à installer'
        ],
        loadAsFrequently: true
    },
    {
        title: 'Licence d\'exploitation de machines à sous',
        description: 'Cette procédure vous permet de faire une demande d\'attestation de travail.',
        type: TypeDemande.DEMANDE_EXPLO_MACHINE_SOUS,
        delai: '3',
        cout: 'Gratuit',
        requestUrl: '/document-administratif/soumission/demande-licence-exploitation-machines-sous',
        serviceLibelleLong: 'La Direction des Etudes et de la Législation Financière',
        serviceLibelleCourt: 'delf',
        loadAsFrequently: true,
        pieceAfournir: ['quittance de cinq millions (5 000 000) de francs CFA', 'procès-verbal de l\'assemblée générale constitutive', 'statut de  la société', ' l\'état des associés', 'liste des administrateurs', 'liste des commissaires aux comptes', 'un extrait du registre du commerce et de crédit mobilier',
            'autorisation du maire de la commune concernée', 'attestation de situation fiscale de la Caisse Nationale de Sécurité Sociale', 'attestation de situation fiscale de l\'Agence Judiciaire du Trésor', 'une copie des titres d\'occupation des locaux, notamment un titre foncier, un contrat de bail ou une autorisation du bailleur', 'documentation technique décrivant les références techniques des\n' +
            'appareils et matériels à utiliser et les règles de fonctionnement des jeux qu\'ils sous-tendent',
            'un mémorandum  indiquant notamment l\'importance des moyens financiers et humains qui seront affectés à l\'équipement et au fonctionnement de l’établissement, le plan de développement prévisionnel de l’établissement de jeu sur cinq (05) ans et l’impact de l’activité de l’établissement de jeux sur l’économie des localités d’implantation ', ' un extrait d\'acte de naissance', ' un extrait de casier judiciaire de moins de trois mois de date', ' un certificat de nationalité', 'un curriculum vitae ', '  photos d\'identité récentes', ' les actes de nomination', ' un rapport d’enquête de moralité']

    },


    {
        title: 'Autorisation d\'ouverture de salles de machines à sous',
        description: 'Cette procédure vous permet de faire une demande d\'ouverture de salles de machines à sous.',
        type: TypeDemande.DEMANDE_OUVER_SALLE_MACHINE_SOUS,
        delai: '3',
        cout: 'Gratuit',
        requestUrl: '/document-administratif/soumission/demande-autorisation-ouverture-salles-machines-sous',
        serviceLibelleLong: 'La Direction des Etudes et de la Législation Financière ',
        serviceLibelleCourt: 'delf',
        pieceAfournir: ['quittance du droit de timbre d\'un million de francs CFA', 'avis du Maire de la commune d\'implantation', 'une copie de la licence d\'exploitation des machines à sous', 'une copie de la quittance de versement de la caution bancaire', 'une copie des titres d\'occupation des locaux notamment un titre foncier, un contrat de bail dûment enregistré ou autorisation du bailleur', 'un état des machines à installer'],
        loadAsFrequently: true
    },

    {
        title: 'Autorisation  d\'organiser les loteries et tombolas',
        description: 'Cette procédure vous permet de faire une demande d\'autorisation pour organiser les loteries et tombolas.',
        type: TypeDemande.DEMANDE_LOTERIES_TOMBOLAS,
        delai: '3',
        cout: 'Gratuit',
        requestUrl: '/document-administratif/soumission/demande-autorisation-organiser-loterie-tombola',
        serviceLibelleLong: 'La Direction des Etudes et de la Législation Financière',
        serviceLibelleCourt: 'delf',
        pieceAfournir: ['quittance de timbre de cinq mille (5 000) francs CFA ', 'procès-verbal de l\'assemblée générale constitutive', 'statut de  la société', ' l\'état des associés', 'extrait du registre du commerce et crédit mobilier',
            'attestation de situation fiscale de la Caisse Nationale de Sécurité Sociale', 'attestation de situation fiscale de l\'Agence Judiciaire du Trésor', 'le règlement de la loterie ou de la tombola authentifié par un notaire', 'objectifs poursuivis à travers l\'organisation du jeu',
            ' la zone territoriale couverte ', ' honoraires', 'le programme des lots mis en jeu '],
        loadAsFrequently: true
    }
];


export const PROCEDURE_DAMOF = [

    {
        title: 'Autorisation d’exercice de l’activité du change manuel',
        description: 'Cette procédure à une entreprise de soumettre une demande d’autorisation d’exercice de l’activité du change manuel et de permettre à la DAMOF de recevoir la demande et de la traiter.',
        type: TypeDemande.AUTORISATION_CHANGE_MANUEL,
        delai: '5',
        cout: 'Gratuit',
        requestUrl: '/document-administratif/soumission/creation-autorisation-change-manuel',
        serviceLibelleLong: 'Direction des Affaires Monétaires et Financières.',
        serviceLibelleCourt: 'damof',
        loadAsFrequently: true,
        pieceAfournir: ['Agrément de change']
    }
];

export const PROCEDURE_BCMS = [
    {
        title: 'Ordre de mission',
        description: 'Cette procédure permet aux agents du Trésor de soumettre une demande d’ordre de mission en ligne et de permettre au service BCMS de recevoir la demande et de la traiter.',
        type: TypeDemande.DEMANDE_ORDRE_MISSION,
        delai: '2',
        cout: 'Gratuit',
        requestUrl: '/document-administratif/soumission/creation-ordre-mission',
        serviceLibelleLong: 'Bureau de la Coordination des Missions et des Services',
        serviceLibelleCourt: 'bcms',
        loadAsFrequently: true
    }
];

export const PROCEDURE_ITT = [
    {
        title: 'Souscription à un engagement à cautionner',
        description: 'Cette procédure permet aux comptables directs du Trésor de soumettre une demande de souscription à un engagement à cautionner en ligne et de permettre à l’Inspection Technique du Trésor (ITT) de recevoir la demande et de la traiter.',
        type: TypeDemande.SOUSCRIPTION_ENGAGEMENT,
        delai: '2',
        cout: 'Gratuit',
        requestUrl: '/document-administratif/soumission/creation-souscription-engagement-cautionner',
        serviceLibelleLong: 'Inspection Technique du Trésor',
        serviceLibelleCourt: 'itt',
        loadAsFrequently: true,
        pieceAfournir: ['Arrêté ou décret de nomination ou nomination en conseil des ministres']
    },
    {
        title: 'Libération de cautionnement d’un comptable public',
        description: 'Cette procédure aux comptables directs du Trésor de soumettre une demande de libération de cautionnement en ligne et de permettre à l’Inspection Technique du Trésor (ITT) de recevoir la demande et de la traiter.',
        type: TypeDemande.LIBERATION_CAUTIONNEMENT,
        delai: '2',
        cout: 'Gratuit',
        requestUrl: '/document-administratif/soumission/creation-liberation-cautionnement',
        serviceLibelleLong: 'Inspection Technique du Trésor',
        serviceLibelleCourt: 'itt',
        loadAsFrequently: true,
        pieceAfournir: ['Acte de nomination du comptable public', 'Attestation de dépôt du dernier compte de gestion', 'Certificat de cessation de service', 'PV de remise de service', 'Dernier bulletin de salaire ou tout autre document constatant la nouvelle situation de l\'agent']
    }
];

export const PROCEDURE_DA = [
    {
        title: 'Déclaration d\'un agent',
        description: 'Cette procédure vous permet de faire la déclaration de votre agent d\'assurance',
        type: TypeDemande.DECLARATION_AGENT,
        delai: '90',
        cout: '5 000 fcfa',
        requestUrl: '/document-administratif/soumission/declaration-agent',
        serviceLibelleLong: 'La Direction des assurances ',
        serviceLibelleCourt: 'da',
        loadAsFrequently: true,
        pieceAfournir: [
            'Extrait de naissance',
            'Casier judiciaire',
            'Traité de nomination agent',
            'Carte de Nationale d\'Identité Burkinabè (CNIB)',
            'Diplome d\'assurance',
            'Attestation de travail',
            'Une photo d\'identitée',
            'Quittance de cinq mille  (5 000) FCFA',
            'Garantie financière',
            'Fiche d\'honorabilité'
        ]
    },
    {
        title: 'Agrément des courtiers d\'assurances ',
        description: 'Cette procédure vous permet de faire une demande d\'agrément des courtiers d\'assurances .',
        type: TypeDemande.DEMANDE_AGREMENT_COURTIER,
        delai: '90',
        cout: 'Gratuit',
        requestUrl: '/document-administratif/soumission/agrement-courtier',
        serviceLibelleLong: 'La Direction des assurances ',
        serviceLibelleCourt: 'da',
        loadAsFrequently: true,
        pieceAfournir: [
            'La déclaration Notariée de Souscription et de Versement (DNSV) ',
            'La déclaration de Souscription et de Versement (DSV)',
            'Le procès-verbal de l\'Assemblée Générale Constitutive (PV/AC) ',
            'Certificat notarié ou certificat du Commissaire aux Comptes',
            'Le spécimen du logo de la société',
            'Le contrat de bail dûment signé ',
            'le siège de la société',
            'Le plan d\'informatisation muni de la licence',
            'Le relevé de compte attestant l\'existence du capital social',
            'La garantie financière d\'au moins dix (10) millions de francs CFA',
            'Le récépissé d\'inscription au Registre du Commerce et du Crédit Mobilier',
            'Le plan d\'affaires comprenant un compte d\'exploitation prévisionnel détaillé',
            'Le reçu de versement du capital social, ou la copie du chèque de dépôt du capital social',
            'L\'assurance de responsabilité civile professionnelle d\'au moins dix (10) millions de francs ',
            'La liste du personnel avec indication du profil de chacun d\'eux des diplômes et les attestions professionnels'
        ]
    },

    {
        title: 'Etablissement de cartes professionnelles d\'agent d\'assurance ',
        description: 'Cette procédure vous permet de faire une demande d\'établissement de carte professionnelle d\'agent d\'assurance.',
        type: TypeDemande.DEMANDE_ETABLISSEMENT_CARTE,
        delai: '90',
        cout: '5 000 fcfa',
        requestUrl: '/document-administratif/soumission/demande-etablissement-carte',
        serviceLibelleLong: 'La Direction des assurances ',
        serviceLibelleCourt: 'da',
        loadAsFrequently: true,
        pieceAfournir: [
            'Extrait de naissance',
            'Casier judiciaire',
            'Traité de nomination agent',
            'Certificat de Nationalité d\'Identité Burkinabè (CNIB)  ',
            'Diplome d\'assurance',
            'Attestation de travail',
            'Une photo d\'identitée',
            'Quittance de cinq mille  (5 000) FCFA',
            'Garantie financière',
            'Fiche d\'honorabilité'
        ]
    },

    {
        title: 'Réclamation relative aux sinistres.',
        description: 'Cette procédure vous permet de faire une demande de réclamation relative aux sinistres.',
        type: TypeDemande.RECLAMATION_SINISTRE,
        delai: '90',
        cout: 'gratuit',
        requestUrl: '/document-administratif/soumission/reclamation-sinistre',
        serviceLibelleLong: 'La Direction des assurances ',
        serviceLibelleCourt: 'da',
        loadAsFrequently: true,
        pieceAfournir: []
    },


    {
        title: 'Autorisation de gestion de fonds maladie',
        description: 'Cette procédure vous permet de faire une demande d\'autorisation de gestion de fonds maladie.',
        type: TypeDemande.AUTO_GESTION_FOND_MALADIE,
        delai: '90',
        cout: 'Gratuit',
        requestUrl: '/document-administratif/soumission/auto-gestion-fond-maladie',
        serviceLibelleLong: 'La Direction des assurances ',
        serviceLibelleCourt: 'da',
        loadAsFrequently: true,
        pieceAfournir: [
            'Audit de conformité ou de non conformité des activités du courtier',
            'Un projet de convention de gestion',
            'Une note explicative démontrant la capacité du courtier à cantonner les fonds reçus,à faire une gestion séparée et à rendre compte de sa gestion régulièrement à l\'entreprise et à la Direction Nationale des Assurances; la capacité technique et organisationnelle du courtier permettant d\'apprécier son aptitude à gérer le risque de façon efficiente',
            'Un élargissement du champ du contrat de responsabilité civile aux nouvelles activités du courtier',
            'Tout autre document jugé pertinent',
            'Une analyse et une mise à jour des élements ayant permis d\'obtenir l\'avis favorable pour la gestion des fonds maladie',
            'Un tableau de bord de tous les contrats en portefeuille dans le cadre de la gestion de fond maladie',
            'Une analyse rétrospective et prospective du developpement de l\'activité de gestion maladie et de son impact sur l\'activité globale du courtier',
            'Tout autre document jugé pertinent'
        ]
    },


    {
        title: 'Agrément ou extension d\'agrément de sociétés d\'assurances ou de réassurances',
        description: 'Cette procédure vous permet de faire la demande d\'agrément ou extension d\'agrément',
        type: TypeDemande.DEMANDE_AGREMENT_EXTENSION,
        delai: '90',
        cout: '5 000 fcfa',
        requestUrl: '/document-administratif/soumission/agrement-extension-assurance',
        serviceLibelleLong: 'La Direction des assurances ',
        serviceLibelleCourt: 'da',
        loadAsFrequently: true,
        pieceAfournir: [
            'Liste établie des branches que l\'entreprise se propose de pratiquer',
            'Acte authentique constitutif de l\'entreprise ou une expédition',
            'Procès-verbal de l\'assemblée générale constitutive',
            'Exemplaire des statuts',
            'Une attestation de dépôt bancaire',
            'La liste des administrateurs et directeurs et du personnel(nom, prénoms, domicile, nationalité,  date et lieu de naissance).',
            'Document pour chacun extrait de leur casier judiciaire datant de moins de trois (3)',
            'Document précisant la nature des risques',
            'Pour chacune des branches,  un exemplaire des polices et imprimés destinés à être distribués au public ou publiés ',
            'Pour chacune des branches, un exemplaire des tarifs',
            'Acte notarié de souscription et de versement du capital',
            'PV de l’AG Constitutive',

            'Liste des Commissaires aux Comptes  titulaire et suppléant(nom, prénoms, domicile, nationalité, lieu et date de naissance pour la personne physique)',
            'Pour chacun: un Casier judiciaire valide,un CV,une attestation  d’inscription au tableau de l’ordre des Experts Comptables, le nom des entités déjà auditées  ,L’engagement  sur l’honneur  des Commissaires  aux Comptes '
        ]

    },

    {
        title: 'Concours de la Direction des Assurances',
        description: 'Cette page contient la liste des concours organisés par la Direction des Assurances',
        type: TypeDemande.RECRUTEMENET_CARDE,
        delai: '1',
        cout: 'gratuit',
        requestUrl: '/document-administratif/soumission/recrutement-cadre',
        serviceLibelleLong: 'La Direction des Assurances ',
        serviceLibelleCourt: 'da',
        loadAsFrequently: true,
        pieceAfournir: [
            'CNIB',
            'Extrait de naissance',
            'CV',
            'Certificat de nationalité',
            'Diplôme requis pour le concours',
            'Attestation de travail',
            'Lettre de motivation',
            'Casier judiciaire',
            'Certificat d\'aptitude'
        ]
    }


];

export const PROCEDURE_RG = [
    {
        title: 'Avance crédit dédouanement de véhicules',
        description: 'Cette procédure à un agent public ou militaire de soumettre une demande d’avance de crédit dédouanement de véhicules en ligne et de permettre à la Recette Générale (RG) de recevoir la demande et de la traiter.',
        type: TypeDemande.AVANCE_CREDIT_DEDOUANEMENT,
        delai: '',
        cout: '11,5% des droits de douanes (intérêt sur le montant qui est accordé)',
        requestUrl: '/document-administratif/soumission/creation-avance-credit-dedouanement-vehicules',
        serviceLibelleLong: 'Recette Générale',
        serviceLibelleCourt: 'rg',
        loadAsFrequently: true,
        pieceAfournir: ['CNIB', ' Bulletin de paie', 'Bulletin de liquidation provisoire', 'Bulletin de liquidation définitive', 'Quittance de paiement']
    },
    {
        title: 'Attestation de reversement',
        description: 'Cette procédure à un agent public ou militaire de soumettre une demande d’attestation de reversement en ligne et de permettre à la Recette Générale (RG) de recevoir la demande et de la traiter.',
        type: TypeDemande.DEMANDE_ATTESTATION_REVERSEMENT,
        delai: '5',
        cout: 'demande timbrée à 200 FCFA',
        requestUrl: '/document-administratif/soumission/creation-attestation-reversement',
        serviceLibelleLong: 'Recette Générale',
        serviceLibelleCourt: 'rg',
        loadAsFrequently: true,
        pieceAfournir: ['Fiche de quittance de paiement']
    }
];

export const PROCEDURE_TMDC = [
    {
        title: 'Délégation de solde',
        description: 'Cette procédure à un poste comptable à l’étranger de soumettre une demande de délégation de solde et de permettre à la TMDC de recevoir la demande et de la traiter.',
        type: TypeDemande.DELEGATION_SOLDE,
        delai: '5',
        cout: 'Gratuit',
        requestUrl: '/document-administratif/soumission/creation-delegation-solde',
        serviceLibelleLong: 'Trésorerie des Missions diplomatiques et Consulaires',
        serviceLibelleCourt: 'tmdc',
        loadAsFrequently: true,
        pieceAfournir: ['Fiche de références du RIB']
    }
];

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService extends KeycloakAuthGuard {
    accessDeniedUrl = 'auth/access';

    constructor(
        protected override readonly router: Router,
        protected readonly keycloak: KeycloakService) {
        super(router, keycloak);
    }

    public async isAccessAllowed(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        if (!this.authenticated) {
            await this.keycloak.login({
                redirectUri: window.location.origin +"/#"+ state.url
            });
        }

        // Get the roles required from the route.
        const requiredRoles = route.data['roles'];

        // Allow the user to proceed if no additional roles are required to access the route.
        if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
            return true;
        }

        if (!this.roles || this.roles.length === 0) {
            return true;
        }
        // Allow the user to proceed if all the required roles are present.
        if (requiredRoles.some((role) => this.roles.includes(role))) {
            return true;
        } else {
            return new Promise<boolean | UrlTree>((resolve, reject) => {
                resolve(this.router.parseUrl(this.accessDeniedUrl));
            });
        }
    }
}

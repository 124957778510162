import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { HomePageComponent } from './components/pages/home-page/home-page.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import {
    CheckDocValidatyComponent
} from './components/pages/check-doc-validity/check-doc-validity/check-doc-validaty.component';
import { checkDocValidityResolver } from './components/pages/document-administratif/demande-administratif.service';
import { PaymentComponent } from './components/pages/payment/payment.component';
import { AuthGuardService } from './security/auth-guard.service';

const routes: Routes = [
    {path: '', component: HomePageComponent},
    {
        path: 'contribution',
        data: {breadcrumb: 'Contributions'},
        loadChildren: () => import('../app/components/pages/contributions/contribution.module').then(m => m.ContributionModule)
    },
    {
        path: 'publication',
        data: {breadcrumb: 'Publications'},
        loadChildren: () => import('../app/components/pages/publications/publication.module').then(m => m.PublicationModule)
    },

    {
        path: 'document-administratif',
        data: {breadcrumb: 'Documents administratif'},
        loadChildren: () => import('../app/components/pages/document-administratif/document-administratif.module').then(m => m.DocumentAdministratifModule)
    },
    {
        path: 'espace-utilisateur',
        data: {breadcrumb: 'Espace utilisateur'},
        loadChildren: () => import('../app/components/pages/espace-utilisateur/espace-utilisateur.module').then(m => m.EspaceUtilisateurModule)
    },
    {
        path: 'verification',
        component: CheckDocValidatyComponent,
        resolve: {checkDocValidity: checkDocValidityResolver},
        data: {breadcrumb: 'Vérification des documents'}
    },

    {path: 'about', component: AboutComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'payment', component: PaymentComponent, canActivate: [AuthGuardService]},

    {path: '**', component: ErrorComponent} // This line will remain down from the whole component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { createRequestOption, formatUrl } from '../../../utils/app-utils';
import { LanayaEndpoint, LanayaPaymentUrlConfig } from './lanaya-url-config';
import {
    Avenant,
    CertificatDetaxe,
    DataListDto,
    DemandePaiement,
    Facture,
    Marche,
    PositionDossier,
    Quittance,
    Retenue,
    SearchInfos
} from './lanaya.model';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LanayaService {

    constructor(private http: HttpClient) {
    }

    public getDemandeByIfu(numeroIfu: string, refContrat?: string): Observable<HttpResponse<DataListDto<DemandePaiement>>> {
        const params = createRequestOption({numeroIfu, refContrat});
        return this.http.get<DataListDto<DemandePaiement>>(LanayaEndpoint.DEMANDE_BY_IFU_URL, {
            observe: 'response',
            params
        });
    }

    public getRetenueByCriteria(numeroIfu: string, demandePaiement?: number, idEngagement?: number): Observable<HttpResponse<DataListDto<Retenue>>> {
        const params = createRequestOption({numeroIfu, demandePaiement, idEngagement});
        return this.http.get<DataListDto<Retenue>>(LanayaEndpoint.RETENUE_BY_CRITERIA_URL, {
            observe: 'response',
            params
        });
    }

    public getPositionByCriteria(contrat?: string, demandePaiement?: number, idEngagement?: number): Observable<HttpResponse<DataListDto<PositionDossier>>> {
        const params = createRequestOption({contrat, demandePaiement, idEngagement});
        return this.http.get<DataListDto<PositionDossier>>(LanayaEndpoint.POSITION_BY_CRITERIA_URL, {
            observe: 'response',
            params
        });
    }

    public getMarcheByIfu(numeroIfu: string): Observable<HttpResponse<DataListDto<Marche>>> {
        return this.http.get<DataListDto<Marche>>(formatUrl(LanayaEndpoint.MARCHE_BY_IFU_URL, numeroIfu), {observe: 'response'});
    }

    // Lanaya Service
    public getQuittanceLanayaByIfu(userId: string): Observable<HttpResponse<Array<Quittance>>> {
        return this.http.get<Array<Quittance>>(formatUrl(LanayaPaymentUrlConfig.LANAYA_QUITTANCE_BY_USER_URL, userId), {observe: 'response'});
    }

    // Nafolo Service
    public getQuittanceNafoloByIfu(numeroIfu: string): Observable<HttpResponse<DataListDto<Quittance>>> {
        return this.http.get<DataListDto<Quittance>>(formatUrl(LanayaEndpoint.NAFOLO_QUITTANCE_BY_IFU_URL, numeroIfu), {observe: 'response'});
    }

    public getAvenantByIfu(numeroIfu: string): Observable<HttpResponse<DataListDto<Avenant>>> {
        return this.http.get<DataListDto<Avenant>>(formatUrl(LanayaEndpoint.AVENANT_BY_IFU_URL, numeroIfu), {observe: 'response'});
    }

    public getFactureByIfu(numeroIfu: string): Observable<HttpResponse<DataListDto<Facture>>> {
        return this.http.get<DataListDto<Facture>>(formatUrl(LanayaEndpoint.FACTURE_BY_IFU_URL, numeroIfu), {observe: 'response'});
    }

    public getCertificatByIfu(numeroIfu: string): Observable<HttpResponse<DataListDto<CertificatDetaxe>>> {
        return this.http.get<DataListDto<CertificatDetaxe>>(formatUrl(LanayaEndpoint.CERTIFICAT_BY_IFU_URL, numeroIfu), {observe: 'response'});
    }

    public checkContribuableExists(searchData: SearchInfos): Observable<HttpResponse<any>> {
        return this.http.get(`${environment.lanaya_dgi_esintax_url}/${searchData.numeroIfu}/${searchData.numeroEsintax}`, {
            observe: 'response'
        });
    }
}

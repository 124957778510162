<!-- Inner Banner -->
<div class="inner-banner inner-bg2">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Nous contacter </h3>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>contact</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Contact Section -->
<div class="contact-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-4">
                <div class="contact-card">
                    <i class='bx bxs-phone'></i>
                    <div class="content">
                        <h3>Numéro  de téléphone </h3>
                        <p><a href="tel:+61283171401">+226 25 32 49 41 / 25 32 49 51</a></p>
                        <span><a href="tel:+61283175421">+226 25 32 49 87 / 25 32 49 89</a></span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-4">
                <div class="contact-card">
                    <i class='bx bx-map'></i>
                    <div class="content">
                        <h3>Adresse</h3>
                        <p>Koulouba,
                            806 avenue de l’Indépendance</p>
                        <span> 01 BP 92 Ouagadougou 01</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-4 offset-lg-0 offset-md-0 offset-sm-3">
                <div class="contact-card">
                    <i class='bx bx-envelope'></i>
                    <div class="content">
                        <h3>Email</h3>
                        <p><a href="mailto:tresorpublic&#64;gov.bf">tresorpublic&#64;gov.bf</a></p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Section End -->

<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>Besoin d'aide ? N'hésitez pas à nous joindre</h2>

            </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->

<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Contactez-nous</h2>
                            <p></p>
                        </div>


                        <form class="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <input  type="text" name="nomComplet"  pInputText [(ngModel)]="asQuestion.nom" placeholder="Votre nom complet">
                                </div>
                                <div class="col-lg-6 col-sm-6">

                                    <input type="text" name="telephone"  pInputText [(ngModel)]="asQuestion.telephone" placeholder="Votre numéro de téléphone">

                                </div>


                                <div class="col-lg-12 col-sm-12">

                                    <input type="email"  name="email"  pInputText [(ngModel)]="asQuestion.email"  placeholder="Votre email">

                                </div>

                                <div class="col-lg-12 col-sm-12">

                                    <input type="text"   name="objet"  pInputText [(ngModel)]="asQuestion.objet"  placeholder="Quelle est votre préoccupation">

                                </div>

                                <div class="col-lg-12 col-md-12">

                                    <textarea    name="description"  pInputText [(ngModel)]="asQuestion.description"cols="30" rows="8" placeholder="Veuillez décrire votre préoccupation" ></textarea>

                                </div>
                                <div class="flex justify-content-center flex-wrap">
                                    <p-progressSpinner *ngIf="isLoading"
                                                       styleClass="w-4rem h-4rem"
                                                       strokeWidth="8"
                                                       fill="var(--surface-ground)"
                                                       animationDuration=".5s" />


                                    <button  pButton *ngIf="!isLoading"  pRipple label="Envoyer" icon="pi pi-send"  (click)="submit()"
                                             pTooltip="Envoyer votre requête" tooltipPosition="top"
                                             class="p-button-primary contact-btn m-1" >
                                    </button>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->

<!-- Map Area -->
<!--<div class="map-area">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d424146.10263665376!2d150.65179679116446!3d-33.847356725232856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ae665e892fdd%3A0x3133f8d75a1ac251!2sSydney%20Opera%20House!5e0!3m2!1sen!2sbd!4v1599988407815!5m2!1sen!2sbd"></iframe>
</div>-->
<!-- Map Area End -->


<div class="navbar-area">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/">
                <img src="assets/img/lanayalogo.png" alt="Logo">
            </a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav m-auto align-items-end">
                     <li>

                         <input class="input-search" type="text" pInputText placeholder="Rechercher une procédure"
                         />   </li>
                    <li>
                        <button class="search ml-2"  type="button" icon="pi pi-search" pButton>Rechercher</button>
                    </li>

                    <li class="nav-item">
                        <a routerLink="/" class="nav-link" routerLinkActive="active"
                           [routerLinkActiveOptions]="{exact: true}">Accueil</a>
                    </li>
                    <li class="nav-item" *ngIf="islogging">
                        <a routerLink="/espace-utilisateur/demandes" class="nav-link" routerLinkActive="active"
                           [routerLinkActiveOptions]="{exact: true}">Tableau de bord</a>
                    </li>
                    <li   class="nav-item"><a routerLink="/contact" class="nav-link"
                                                                                  routerLinkActive="active"
                                                                                  [routerLinkActiveOptions]="{exact: true}">Contacts</a>
                    </li>
                    <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">FAQ</a></li>
                    <li class=" ml-8 "*ngIf="!islogging">
                        <a class="ins" (click)="login()" type="button" style="margin-right: 15px">Connexion</a>

                    </li>
                    <li *ngIf="!islogging">
                        <a class="connecter" (click)="register()" type="button">Inscription</a>
                    </li>
                </ul>
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item">
                        <a routerLink="/" class="nav-link" routerLinkActive="active"
                           [routerLinkActiveOptions]="{exact: true}">Accueil</a>
                    </li>
                    <li class="nav-item" *ngIf="islogging">
                        <a routerLink="/espace-utilisateur/demandes" class="nav-link" routerLinkActive="active"
                           [routerLinkActiveOptions]="{exact: true}">Tableau de bord</a>
                    </li>
                    <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Contacts</a></li>
                    <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">FAQ</a></li>
                    <li>

                        <input class="input-search" type="text" pInputText placeholder="Rechercher une procédure"
                        />   </li>
                    <li class="mt-2">
                        <button class=" search ml-2"  type="button" icon="pi pi-search" pButton>Rechercher</button>
                    </li>


                    <li *ngIf="!islogging" class="mt-2">
                        <a class="ins" (click)="login()" type="button" style="margin-right: 15px">Connexion</a>
                        <a class="connecter" (click)="register()" type="button">Inscription</a>

                    </li>
                </ul>
                <div class="option-item" *ngIf="islogging"  type="button">
                    @if (user !== undefined) {
                    <div class="dropdown">
                   <p-avatar label="" styleClass="mr-2" size="large"
                             shape="circle">{{ user.firstName.charAt(0).toLocaleUpperCase() }}
                    </p-avatar>
  <div class="dropdown-content">
    <a routerLink="/espace-utilisateur/mon-profil" ><i class="pi pi-user"></i> Mon profil</a>
    <a  routerLink="/espace-utilisateur/demandes"><i class="pi pi-home"></i>Tableau de bord</a>
    <a (click)="logout()" type="button"><i class="pi pi-sign-out"></i> Déconnexion</a>
  </div>
</div>


                    }

                </div>
            </div>

        </nav>
    </div>

</div>
<!-- End Navbar Area -->

import { SUIVI_DOSSIER_SERVICE_PREFIX } from '../../../utils/app-utils';

export class LanayaEndpoint {
    static readonly LANAYA_ROOT_URL = `${SUIVI_DOSSIER_SERVICE_PREFIX}/lanaya`;
    static readonly MARCHE_BY_IFU_URL = `${LanayaEndpoint.LANAYA_ROOT_URL}/marches/$numeroIfu$`;
    static readonly LANAYA_QUITTANCE_BY_IFU_URL = `${LanayaEndpoint.LANAYA_ROOT_URL}/quittances/$userId$`;
    static readonly NAFOLO_QUITTANCE_BY_IFU_URL = `${LanayaEndpoint.LANAYA_ROOT_URL}/quittances/$numeroIfu$`;
    static readonly AVENANT_BY_IFU_URL = `${LanayaEndpoint.LANAYA_ROOT_URL}/avenants/$numeroIfu$`;
    static readonly FACTURE_BY_IFU_URL = `${LanayaEndpoint.LANAYA_ROOT_URL}/factures/$numeroIfu$`;
    static readonly CERTIFICAT_BY_IFU_URL = `${LanayaEndpoint.LANAYA_ROOT_URL}/certificat-detaxes/$numeroIfu$`;
    static readonly DEMANDE_BY_IFU_URL = `${LanayaEndpoint.LANAYA_ROOT_URL}/demande-paiements`;
    static readonly RETENUE_BY_CRITERIA_URL = `${LanayaEndpoint.LANAYA_ROOT_URL}/retenues`;
    static readonly POSITION_BY_CRITERIA_URL = `${LanayaEndpoint.LANAYA_ROOT_URL}/positions`;
}

<footer class="footer-area">
    <div class="container pt-6">
        <div class="row  d-flex justify-content-center">

            <div class="col-lg-2 col-md-6 col-sm-12">
                <div>
                    <img class="logo " src="assets/img/logo.jpeg" alt="Logo du Trésor Public" />
                </div>
            </div>


            <div class="col-lg-2 col-md-6 col-sm-12">
                <h3>Contacts</h3>
                <ul>
                    <li><i class="pi pi-phone"></i> &nbsp; <strong>  226xxxxxxxxxx</strong></li>
                    <li><i class="pi pi-envelope"></i> &nbsp; <strong> dgtcp&#64;tresor.gov.bf</strong></li>
                    <li><i class="pi pi-whatsapp"></i> &nbsp; <strong> 700</strong></li>
                    <li><i class="pi pi-directions"></i> &nbsp; <strong> Ouaga 2000 <br> blvd M.KADDHAFI</strong></li>
                </ul>

            </div>

            <div class="col-lg-2 col-md-6 col-sm-12">
                <h3>E-services</h3>
                <ul class="white-text">

                    <li><a href="https://www.etimbre.dgi.bf/home/" target="_blank">E TIMBRE</a></li>
                    <li><a href="https://esintax.bf/" target="_blank">E SINTAX</a></li>
                    <li><a href="https://edouanes.bf/login-edouanes" target="_blank">E DOUANES</a></li>
                    <li><a href="https://ventes-encheres.finances.bf/" target="_blank">E ENCHERES</a></li>
                    <li><a href="https://www.visaburkina.bf/" target="_blank">E VISA</a></li>
                    <li><a href="https://www.campusfaso.bf/" target="_blank"> CAMPUS FASO</a></li>

                </ul>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-12">
                <h3>Liens utiles</h3>
                <ul class="white-text">
                    <li><a href="https://www.presidencedufaso.bf/" target="_blank">PF</a></li>
                    <li><a href="https://www.finances.gov.bf/accueil" target="_blank">MEF</a></li>
                    <li><a href="https://esintax.bf/" target="_blank">DGTCP</a></li>
                    <li><a href="http://www.douanes.bf/sydoniabf/index.jsf" target="_blank">DGD</a></li>
                    <li><a href="https://dgi.bf/" target="_blank">DGI</a></li>
                    <li><a href="https://dgaie.gov.bf/" target="_blank">DGAIE</a></li>
                    <li><a href="https://it.finances.bf/" target="_blank">DGSI</a></li>
                    <li><a href="https://cil.bf/" target="_blank">CIL</a></li>

                </ul>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-12">
                <h3>Infos utiles</h3>
                <ul class="white-text">
                    <li><a href="#">CGU</a></li>
                    <li><a href="#">Politique de <br>
                    confidentialité</a>
                    </li>
                </ul>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-12">
                <h3>Facebook</h3>
                <iframe
                    src="https://www.facebook.com/plugins/page.php?href=https://web.facebook.com/tresor.bf?locale=fr_FR&tabs=timeline&width=300&height=450&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                    width="100%"
                    height="300"
                    style="border:none; overflow:hidden"
                    scrolling="no"
                    frameborder="0"
                    allowTransparency="true"
                    allow="encrypted-media">
                </iframe>
            </div>
        </div>
    </div>

</footer>


export class UserProfile {
    id?: string;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    attributes: any;

    constructor(userProfile: any) {
        this.id = userProfile.id;
        this.username = userProfile.username;
        this.firstName = userProfile.firstName;
        this.lastName = userProfile.lastName;
        this.email = userProfile.email;
        this.attributes = userProfile.attributes;
    }
}

export interface Attributes {
    telephone: any[];
    adresse: any[];
}

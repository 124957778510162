import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckValidityData } from '../../document-administratif/demande-administratif.models';

@Component({
    selector: 'app-check-doc-validity',
    templateUrl: './check-doc-validaty.component.html',
    styleUrl: './check-doc-validaty.component.scss'
})
export class CheckDocValidatyComponent implements OnInit {
    checkValidityData: CheckValidityData;
    isVerified = false;

    constructor(private activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe(({checkDocValidity}) => {
            this.checkValidityData = checkDocValidity;
            this.isVerified = !!checkDocValidity.numeroDemande;
        });
    }
}

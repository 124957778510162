import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PieceJointe, Publication } from './publication.model';
import { PublicationEndpoint } from './publication-url-config';
import { createRequestOption, downloadBinaryFile, formatUrl } from '../../utils/app-utils';

@Injectable({
    providedIn: 'root'
})
export class PublicationService {

    constructor(private http: HttpClient) {
    }

    public getByAllVisible(): Observable<HttpResponse<Array<Publication>>> {
        return this.http.get<Array<Publication>>(PublicationEndpoint.PUBLICATION_BY_ALL_VISIBLE_URL, {observe: 'response'});
    }

    public getByAllVisibleWithLimit(limit: number): Observable<HttpResponse<Array<Publication>>> {
        const params = createRequestOption({limit});
        return this.http.get<Array<Publication>>(PublicationEndpoint.PUBLICATION_BY_ALL_VISIBLE_URL,
            {observe: 'response', params});
    }

    public downoadFile(pj: PieceJointe) {
        this.http.get<PieceJointe>(formatUrl(PublicationEndpoint.PUBLICATION_PJ_BY_ID_URL, pj.id))
            .subscribe({
                next: data => {
                    downloadBinaryFile(data.type, data.fichier, data.nom);
                }
            });
    }
}
